/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { Satellite } from '@material-ui/icons';
import { createAction, createReducer } from 'utils/reducer';
// Local constants
const PATH = 'home/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const HOME = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_BIKES: `${PATH}FETCH_BIKES`,
  FETCH_BIKES_SUCCESS: `${PATH}FETCH_BIKES_SUCCESS`,
  FETCH_BIKES_ERROR: `${PATH}FETCH_BIKES_ERROR`,
  FETCH_BIKES_TRANSFERRED: `${PATH}FETCH_BIKES_TRANSFERRED`,
  FETCH_BIKES_TRANSFERRED_SUCCESS: `${PATH}FETCH_BIKES_TRANSFERRED_SUCCESS`,
  FETCH_BIKES_TRANSFERRED_ERROR: `${PATH}FETCH_BIKES_TRANSFERRED_ERROR`,
  VALIDATE_TRANSFERS: `${PATH}VALIDATE_TRANSFERS`,
  VALIDATE_TRANSFERS_ERROR: `${PATH}VALIDATE_TRANSFERS_ERROR`,
  VALIDATE_TRANSFERS_SUCCESS: `${PATH}VALIDATE_TRANSFERS_SUCCESS`,
  VALIDATE_TRANSFERS_SELLER: `${PATH}VALIDATE_TRANSFERS_SELLER`,
  VALIDATE_TRANSFERS_SELLER_SUCCESS: `${PATH}VALIDATE_TRANSFERS_SELLER_SUCCESS`,
  VALIDATE_TRANSFERS_SELLER_FAILURE: `${PATH}VALIDATE_TRANSFERS_SELLER_FAILURE`,
  VALIDATE_TRANSFERS_BUYER: `${PATH}VALIDATE_TRANSFERS_BUYER`,
  VALIDATE_TRANSFERS_BUYER_ERROR: `${PATH}VALIDATE_TRANSFERS_BUYER_ERROR`,
  VALIDATE_TRANSFERS_BUYER_SUCCESS: `${PATH}VALIDATE_TRANSFERS_BUYER_SUCCESS`,
  FETCH_BIKES_PENDING: `${PATH}FETCH_BIKES_PENDING`,
  FETCH_BIKES_PENDING_SUCCESS: `${PATH}FETCH_BIKES_PENDING_SUCCESS`,
  FETCH_BIKES_PENDING_ERROR: `${PATH}FETCH_BIKES_PENDING_ERROR`,

  FETCH_INSURABLE_BIKES: `${PATH}FETCH_INSURABLE_BIKES`,
  FETCH_INSURABLE_BIKES_SUCCESS: `${PATH}FETCH_INSURABLE_BIKES_SUCCESS`,
  FETCH_INSURABLE_BIKES_ERROR: `${PATH}FETCH_INSURABLE_BIKES_ERROR`,

  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
  CLEAR_ALERT_DELETE: `${PATH}CLEAR_ALERT_DELETE`,
  OPEN_ALERT_DELETE: `${PATH}OPEN_ALERT_DELETE`,
  DELETE_TRANSFER: `${PATH}DELETE_TRANSFER`,
  DELETE_TRANSFER_SUCCESS: `${PATH}DELETE_TRANSFER_SUCCESS`,
  DELETE_TRANSFER_ERROR: `${PATH}DELETE_TRANSFER_ERROR`,
  TOGGLE_MODAL_PAYMENT_STATUS: `${PATH}TOGGLE_MODAL_PAYMENT_STATUS`,
  SET_IS_OPEN_TAKE_INSURANCE_MODAL: `${PATH}SET_IS_OPEN_TAKE_INSURANCE_MODAL`,
  TOGGLE_SHOW_HIDDE_PILOT_ALERT: `${PATH}TOGGLE_SHOW_HIDDE_PILOT_ALERT`,
  CHANGE_LIST_TYPE: `${PATH}CHANGE_LIST_TYPE`,
  SET_IS_MARKET_PLACE: `${PATH}SET_IS_MARKET_PLACE`,

  GET_PUBLISHED_BIKES: `${PATH}GET_PUBLISHED_BIKES`,
  GET_PUBLISHED_BIKES_SUCCESS: `${PATH}GET_PUBLISHED_BIKES_SUCCESS`,
  GET_PUBLISHED_BIKES_FAIL: `${PATH}GET_PUBLISHED_BIKES_FAIL`,

  SET_FORM_FILTER: `${PATH}SET_FORM_FILTER`,
  RESET_FORM_FILTER: `${PATH}RESET_FORM_FILTER`,
  SET_FORM_FILTER_SUCCESS: `${PATH}SET_FORM_FILTER_SUCCESS`,
  SET_FORM_FILTER_FAIL: `${PATH}SET_FORM_FILTER_FAIL`,

  SET_STORE_HOME_FORM_FILTER: `${PATH}SET_STORE_HOME_FORM_FILTER`,
  RESET_STORE_HOME_FORM_FILTER: `${PATH}RESET_STORE_HOME_FORM_FILTER`,
  CHANGE_VEHICLE_TYPE_STORE_FILTER_FORM: `${PATH}CHANGE_VEHICLE_TYPE_STORE_FILTER_FORM`,

  SET_PAGINATION: `${PATH}SET_PAGINATION`,
  RESET_PAGINATION: `${PATH}RESET_PAGINATION`,
  SET_PAGINATION_SUCCESS: `${PATH}SET_PAGINATION_SUCCESS`,
  SET_PAGINATION_FAIL: `${PATH}SET_PAGINATION_FAIL`,
  RESET_STORE_HOME_PAGINATION: `${PATH}RESET_STORE_HOME_PAGINATION`,
  SET_STORE_HOME_PAGINATION: `${PATH}SET_STORE_HOME_PAGINATION`,

  TOGGLE_SHOW_FILTERS: `${PATH}TOGGLE_SHOW_FILTERS`,
  SET_SORT_BY: `${PATH}SET_SORT_BY`,

  TOGGLE_SET_SELLER_EMAIL_DIALOG: `${PATH}TOGGLE_SET_SELLER_EMAIL_DIALOG`,

  SET_SELLER_EMAIL: `${PATH}SET_SELLER_EMAIL`,
  SET_SELLER_EMAIL_SUCCESS: `${PATH}SET_SELLER_EMAIL_SUCCESS`,
  SET_SELLER_EMAIL_FAIL: `${PATH}SET_SELLER_EMAIL_FAIL`,

  TOGGLE_REPORT_PUBLISH: `${PATH}TOGGLE_REPORT_PUBLISH`,

  REPORT_PUBLISH: `${PATH}REPORT_PUBLISH`,
  REPORT_PUBLISH_SUCCESS: `${PATH}REPORT_PUBLISH_SUCCESS`,
  REPORT_PUBLISH_FAIL: `${PATH}REPORT_PUBLISH_FAIL`,

  REQUEST_ACCESS: `${PATH}REQUEST_ACCESS`,
  REQUEST_ACCESS_SUCCESS: `${PATH}REQUEST_ACCESS_SUCCESS`,
  REQUEST_ACCESS_ERROR: `${PATH}REQUEST_ACCESS_ERROR`,

  REMEMBER_REGISTER_BIKE: `${PATH}REMEMBER_REGISTER_BIKE`,
  REMEMBER_REGISTER_BIKE_SUCCESS: `${PATH}REMEMBER_REGISTER_BIKE_SUCCESS`,
  REMEMBER_REGISTER_BIKE_ERROR: `${PATH}REMEMBER_REGISTER_BIKE_ERROR`,

  REMEMBER_ACCESS_FOR_BIKE: `${PATH}REMEMBER_ACCESS_FOR_BIKE`,
  REMEMBER_ACCESS_FOR_BIKE_SUCCESS: `${PATH}REMEMBER_ACCESS_FOR_BIKE_SUCCESS`,
  REMEMBER_ACCESS_FOR_BIKE_ERROR: `${PATH}REMEMBER_ACCESS_FOR_BIKE_ERROR`,

  REMOVE_STORE_ACCESS: `${PATH}REMOVE_STORE_ACCESS`,
  REMOVE_STORE_ACCESS_SUCCESS: `${PATH}REMOVE_STORE_ACCESS_SUCCESS`,
  REMOVE_STORE_ACCESS_ERROR: `${PATH}REMOVE_STORE_ACCESS_ERROR`,

  EXPORT_TO_CSV_BIKE_DATA: `${PATH}EXPORT_TO_CSV_BIKE_DATA`,
  EXPORT_TO_CSV_BIKE_DATA_SUCCESS: `${PATH}EXPORT_TO_CSV_BIKE_DATA_SUCCESS`,

  CLEAR_CSV_DATA: `${PATH}CLEAR_CSV`,
  CLEAR_PILOT_ALERT: `${PATH}CLEAR_PILOT_ALERT`,
  CLEAR_TRANSFER_ALERT: `${PATH}CLEAR_TRANSFER_ALERT`,
  CLEAR_SORT_BY: `${PATH}CLEAR_SORT_BY`,
  CLEAR_STORE_HOME_SORT_BY: `${PATH}CLEAR_STORE_HOME_SORT_BY`,

  SET_GET_ALL_DATA: `${PATH}SET_GET_ALL_DATA`,

  GET_BIKES_FOR_STORE: `${PATH}GET_BIKES_FOR_STORE`,
  GET_BIKES_FOR_STORE_SUCCESS: `${PATH}GET_BIKES_FOR_STORE_SUCCESS`,
  GET_BIKES_FOR_STORE_FAIL: `${PATH}GET_BIKES_FOR_STORE_FAIL`,

  SET_PATH_TYPE: `${PATH}SET_PATH_TYPE`,

  GET_USERS_COUNT: `${PATH}GET_USERS_COUNT`,
  GET_USERS_COUNT_SUCCESS: `${PATH}GET_USERS_COUNT_SUCCESS`,
  GET_USERS_COUNT_ERROR: `${PATH}GET_USERS_COUNT_ERROR`,

  GET_BIKES_COUNT: `${PATH}GET_BIKES_COUNT`,
  GET_BIKES_COUNT_SUCCESS: `${PATH}GET_BIKES_COUNT_SUCCESS`,
  GET_BIKES_COUNT_ERROR: `${PATH}GET_BIKES_COUNT_ERROR`,

  SET_STORE_HOME_SORT_BY: `${PATH}SET_STORE_HOME_SORT_BY`,

  SET_LAST_Y_SCROLL: `${PATH}SET_LAST_Y_SCROLL`,
  PERSIST_MARKETPLACE_TYPE_LIST: `${PATH}PERSIST_MARKETPLACE_TYPE_LIST`,
  SET_LAST_MARKETPLACE_LIST_TYPE: `${PATH}SET_LAST_MARKETPLACE_LIST_TYPE`,

  CHANGE_VEHICLE_TYPE_CONTROLS: `${PATH}CHANGE_VEHICLE_TYPE_CONTROLS`,

  UPDATE_USER_ALERTED_IN_LANDING: `${PATH}UPDATE_USER_ALERTED_IN_LANDING`,
  UPDATE_USER_ALERTED_IN_LANDING_SUCCESS: `${PATH}UPDATE_USER_ALERTED_IN_LANDING_SUCCESS`,
  UPDATE_USER_ALERTED_IN_LANDING_ERROR: `${PATH}UPDATE_USER_ALERTED_IN_LANDING_ERROR`,

  RESET_USER_ALERTED_IN_LANDING: `${PATH}RESET_USER_ALERTED_IN_LANDING`,

  FETCH_ADS: `${PATH}FETCH_ADS`,
  FETCH_ADS_SUCCESS: `${PATH}FETCH_ADS_SUCCESS`,
  FETCH_ADS_FAIL: `${PATH}FETCH_ADS_FAIL`,
  CLICK_AD: `${PATH}CLICK_AD`,
  CLICK_AD_SUCCESS: `${PATH}CLICK_AD_SUCCESS`,
  CLICK_AD_FAIL: `${PATH}CLICK_AD_FAIL`,

};

// actions
export const homeActions = {
  fetchBikes: createAction(HOME.FETCH_BIKES),
  fetchAds: createAction(HOME.FETCH_ADS, 'payload'),
  clickAd: createAction(HOME.CLICK_AD, 'payload'),
  fetchBikesTransferred: createAction(HOME.FETCH_BIKES_TRANSFERRED),
  fetchBikesPending: createAction(HOME.FETCH_BIKES_PENDING),
  fetchInsurableBikes: createAction(HOME.FETCH_INSURABLE_BIKES),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetControls: createAction(HOME.RESET_CONTROLS, 'payload'),
  changeVehicleTypeControls: createAction(HOME.CHANGE_VEHICLE_TYPE_CONTROLS),
  validateTransfer: createAction(HOME.VALIDATE_TRANSFERS),
  validateTransferBuyer: createAction(HOME.VALIDATE_TRANSFERS_BUYER),
  validateTransferSeller: createAction(HOME.VALIDATE_TRANSFERS_SELLER),
  clearAlert: createAction(HOME.CLEAR_ALERT),
  openAlert: createAction(HOME.OPEN_ALERT),
  clearAlertDelete: createAction(HOME.CLEAR_ALERT_DELETE),
  openAlertDelete: createAction(HOME.OPEN_ALERT_DELETE),
  deleteTransfer: createAction(HOME.DELETE_TRANSFER, 'payload'),
  toggleModalPaymentStatus: createAction(HOME.TOGGLE_MODAL_PAYMENT_STATUS, 'payload'),
  setIsOpenTakeInsuranceModal: createAction(HOME.SET_IS_OPEN_TAKE_INSURANCE_MODAL, 'payload'),
  togglePilotAlert: createAction(HOME.TOGGLE_SHOW_HIDDE_PILOT_ALERT, 'payload'),
  changeListType: createAction(HOME.CHANGE_LIST_TYPE, 'payload'),
  setTypeScreen: createAction(HOME.SET_IS_MARKET_PLACE, 'payload'),

  getPublishedBikes: createAction(HOME.GET_PUBLISHED_BIKES, 'payload'),

  setFilterForm: createAction(HOME.SET_FORM_FILTER, 'payload'),
  resetFilterForm: createAction(HOME.RESET_FORM_FILTER),

  setStoreHomeFilterForm: createAction(HOME.SET_STORE_HOME_FORM_FILTER, 'payload'),
  resetStoreHomeFilterForm: createAction(HOME.RESET_STORE_HOME_FORM_FILTER),
  changevehicleTypeStoreFilterForm: createAction(HOME.CHANGE_VEHICLE_TYPE_STORE_FILTER_FORM),

  setPagination: createAction(HOME.SET_PAGINATION, 'payload'),
  setStoreHomePagination: createAction(HOME.SET_STORE_HOME_PAGINATION, 'payload'),
  resetMarketplacePagination: createAction(HOME.RESET_PAGINATION),
  resetStoreHomePagination: createAction(HOME.RESET_STORE_HOME_PAGINATION),

  setShowFilters: createAction(HOME.TOGGLE_SHOW_FILTERS, 'payload'),
  setSortBy: createAction(HOME.SET_SORT_BY, 'payload'),
  setStoreHomeSortBy: createAction(HOME.SET_STORE_HOME_SORT_BY, 'payload'),

  toggleSetSellerEmailDialog: createAction(HOME.TOGGLE_SET_SELLER_EMAIL_DIALOG, 'payload'),
  setSellerEmail: createAction(HOME.SET_SELLER_EMAIL, 'payload'),
  setErrorsSellerEmail: createAction(HOME.SET_SELLER_EMAIL_FAIL, 'payload'),

  toggleReportPublish: createAction(HOME.TOGGLE_REPORT_PUBLISH, 'payload'),

  reportPublish: createAction(HOME.REPORT_PUBLISH, 'payload'),

  requestAccess: createAction(HOME.REQUEST_ACCESS, 'payload'),

  rememberRegisterBike: createAction(HOME.REMEMBER_REGISTER_BIKE, 'payload'),
  rememberAccessForBike: createAction(HOME.REMEMBER_ACCESS_FOR_BIKE, 'payload'),

  removeStoreAccess: createAction(HOME.REMOVE_STORE_ACCESS, 'payload'),

  exportToCsvBikeData: createAction(HOME.EXPORT_TO_CSV_BIKE_DATA, 'payload'),

  clearCsvData: createAction(HOME.CLEAR_CSV_DATA),
  clearPilotAlert: createAction(HOME.CLEAR_PILOT_ALERT),
  clearTransferAlert: createAction(HOME.CLEAR_TRANSFER_ALERT),
  clearSortBy: createAction(HOME.CLEAR_SORT_BY, 'payload'),
  clearStoreHomeSortBy: createAction(HOME.CLEAR_STORE_HOME_SORT_BY, 'payload'),

  setGetAllData: createAction(HOME.SET_GET_ALL_DATA, 'payload'),
  getBikesForStore: createAction(HOME.GET_BIKES_FOR_STORE, 'payload'),
  setPathType: createAction(HOME.SET_PATH_TYPE, 'payload'),

  getUsersCount: createAction(HOME.GET_USERS_COUNT),
  getBikesCount: createAction(HOME.GET_BIKES_COUNT),

  setLastYScroll: createAction(HOME.SET_LAST_Y_SCROLL, 'payload'),

  persistMarketplaceListType: createAction(HOME.PERSIST_MARKETPLACE_TYPE_LIST),
  setLastMarketplaceListType: createAction(HOME.SET_LAST_MARKETPLACE_LIST_TYPE),

  updateUserAlertedInLanding: createAction(HOME.UPDATE_USER_ALERTED_IN_LANDING, 'payload'),
  resetUserAlertedInLanding: createAction(HOME.RESET_USER_ALERTED_IN_LANDING),
};

const initialState = {
  pilot: {
    filterBikesBy: 'CURRENT_BIKES', // CURRENT_BIKES, PENDING_BIKES, TRANSFERRED_BIKES
  },
  showFilters: false, // show the filter form
  showFloatButton: true,
  loading: false,
  loadingUpdateUserAlertedInLanding: false,
  error: false,
  message: '',
  listType: 'mozaic-list', // normal-list, mozaic-list
  lastMarketplaceListType: null,
  lastYScroll: null,
  isUserAlertedInLanding: false,
  marketplaceFilterFormChanged: false,
  filterForm: {
    serialCode: '',
    categoryId: '',
    brandId: [],
    modelId: [],
    minYear: '',
    maxYear: '',
    sizeId: '',
    rimId: '',
    minPrice: '',
    maxPrice: '',
    regionId: '',
    communeId: [],
    paymentOption: '',
    workshopBook: '',
    sellerType: '',
    documentation: '',
    shippingType: '',
    vehicleTypeId: 1,
    searchBikfyPublications: false,
  },
  storeHomeFilterForm: {
    serialCode: '',
    categoryId: '',
    brandId: [],
    modelId: [],
    minYear: '',
    maxYear: '',
    sizeId: '',
    rimId: '',
    minPrice: '',
    maxPrice: '',
    regionId: '',
    communeId: [],
    paymentOption: '',
    workshopBook: '',
    sellerType: '',
    documentation: '',
    shippingType: '',
    vehicleTypeId: 1,
  },
  pagination: {
    total: 0,
    page: 0,
    limit: 15,
    offset: 0,
  },
  paginationStoreHome: {
    total: 0,
    page: 0,
    limit: 15,
    offset: 0,
  },
  sortBy: {
    field: 'credit_card_with_fees',
    order: 'RANDOM',
  },
  storeHomeSortBy: {
    field: 'my_registers',
    order: '',
  },
  // TODO: check those below in which cases it is used
  formControls: {
    bikeFilter: 1, // este aplica solo al pilot, se ha creado pilot.filterBikesBy
    idTransferDelete: 0,
    vehicleTypes: [1, 2],
    type: '',
    message: '',
  },
  transfersPending: false,
  transfersPendingBuyer: false,
  transfersPendingSeller: false,
  alert: false,
  alertDelete: false,
  deleteSuccess: false,
  deleteError: false,
  modals: {
    paymentStatus: {
      show: false,
    },
    setSellerEmail: {
      open: false,
    },
    takeInsurance: {
      isOpen: true,
    },
    reportPublish: {
      open: false,
      bikeMarketId: 0,
    },
  },
  pilotAlert: {
    showAlert: false,
    alertType: '',
    severity: '',
  },
  isSellerBikes: false,
  isExternalPayment: false,
  isMarketPlace: false,
  formSellerEmailValues: {
    buyerName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerLastName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerEmail: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerPhone: {
      value: '',
      isValid: true,
      helperText: '',
    },
    emailMessage: {
      value: 'Hola, vi tu Bike publicada en BIKFY, ¿sigue disponible?',
      isValid: true,
      helperText: '',
    },
    acceptBikfyConditions: {
      value: false,
      isValid: true,
      helperText: '',
    },
    errors: false,
  },
  bikeId: 0,
  transferId: 0,
  csvData: [],
  usersCount: 0,
  bikesCount: 0,
  getAllData: true,
  pathType: '',
  ads: [],
};

const home = createReducer(initialState, {
  [HOME.FETCH_ADS](state) {
    state.loadingAd = true;
    state.errorAdd = false;
    state.ads = [];
  },
  [HOME.FETCH_ADS_SUCCESS](state, action) {
    state.loadingAd = false;
    state.errorAd = false;
    state.ads = action.payload;
  },
  [HOME.FETCH_ADS_ERROR](state, action) {
    state.loadingAd = false;
    state.errorAd = true;
  },

  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [HOME.RESET_CONTROLS](state, action) {
    state.formControls = { ...initialState.formControls };
    state.transfersPending = false;
    state.transfersPendingBuyer = false;
    state.deleteSuccess = false;
    state.deleteError = false;
    state.alert = false;
    state.alertDelete = false;
    state.formControls.bikeFilter = action.payload === 'delete' ? 3 : 1;
  },
  [HOME.CHANGE_VEHICLE_TYPE_CONTROLS](state, action) {
    state.filterForm = {
      ...initialState.filterForm,
      vehicleTypeId: state.filterForm.vehicleTypeId,
      searchBikfyPublications: state.filterForm.searchBikfyPublications,
    };
  },
  [HOME.FETCH_BIKES](state) {
    state.loading = true;
    state.error = false;
  },
  [HOME.FETCH_BIKES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [HOME.FETCH_BIKES_ERROR](state, action) {
    state.loading = false;
    state.error = true;
  },
  [HOME.FETCH_BIKES_TRANSFERRED](state) {
    state.loading = true;
    state.error = false;
  },
  [HOME.FETCH_BIKES_TRANSFERRED_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [HOME.FETCH_BIKES_TRANSFERRED_ERROR](state, action) {
    state.loading = false;
    state.error = true;
  },
  [HOME.VALIDATE_TRANSFERS](state) {
    state.error = false;
  },
  [HOME.VALIDATE_TRANSFERS_SUCCESS](state) {
    return {
      ...state,
      error: false,
      transfersPending: true,
      // pilotAlert: {
      //   ...state.pilotAlert,
      //   showAlert: true,
      //   alertType: 'pendingTransfers',
      //   severity: 'info',
      // },
    };
  },
  [HOME.VALIDATE_TRANSFERS_ERROR](state) {
    state.error = true;
  },
  [HOME.VALIDATE_TRANSFERS_BUYER](state) {
    state.error = false;
  },
  [HOME.VALIDATE_TRANSFERS_BUYER_SUCCESS](state) {
    return {
      ...state,
      error: false,
      transfersPendingBuyer: true,
      // pilotAlert: {
      //   ...state.pilotAlert,
      //   showAlert: true,
      //   alertType: 'pendingTransfersByBuyer',
      //   severity: 'info',
      // },
    };
  },
  [HOME.VALIDATE_TRANSFERS_BUYER_ERROR](state) {
    state.error = true;
  },
  [HOME.VALIDATE_TRANSFERS_SELLER](state) {
    return {
      ...state,
      error: false,
    };
  },
  [HOME.VALIDATE_TRANSFERS_SELLER_SUCCESS](state) {
    return {
      ...state,
      error: false,
      transfersPendingSeller: true,
      // pilotAlert: {
      //   ...state.pilotAlert,
      //   showAlert: true,
      //   alertType: 'pendingTransfers',
      //   severity: 'info',
      // },
    };
  },
  [HOME.VALIDATE_TRANSFERS_SELLER_FAILURE](state) {
    return {
      ...state,
      error: false,
      transfersPendingSeller: false,
      // pilotAlert: {
      //   ...state.pilotAlert,
      //   showAlert: false,
      // },
    };
  },
  [HOME.FETCH_BIKES_PENDING](state) {
    state.loading = true;
    state.error = false;
  },
  [HOME.FETCH_BIKES_PENDING_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      error: false,
      isSellerBikes: action.payload.isSellerBikes,
      isExternalPayment: action.payload.isExternalPayment,
      pilotAlert: {
        ...state.pilotAlert,
        showAlert: action.payload.showAlert,
        alertType: action.payload.isExternalPayment
          ? 'pendingTransfersExternalSeller'
          : 'pendingTransfersSeller',
        severity: 'error',
      },
    };
  },
  [HOME.FETCH_BIKES_PENDING_ERROR](state) {
    state.loading = false;
    state.error = true;
  },

  [HOME.FETCH_INSURABLE_BIKES](state) {
    state.loading = true;
    state.error = false;
  },
  [HOME.FETCH_INSURABLE_BIKES_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
  },
  [HOME.FETCH_INSURABLE_BIKES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [HOME.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [HOME.OPEN_ALERT](state) {
    state.alert = true;
  },
  [HOME.CLEAR_ALERT_DELETE](state) {
    state.alertDelete = false;
  },
  [HOME.OPEN_ALERT_DELETE](state) {
    state.alertDelete = true;
  },
  [HOME.DELETE_TRANSFER](state) {
    state.loading = true;
    state.error = false;
  },
  [HOME.DELETE_TRANSFER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.deleteSuccess = true;
  },
  [HOME.DELETE_TRANSFER_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.deleteError = true;
  },
  [HOME.TOGGLE_MODAL_PAYMENT_STATUS](state, action) {
    return {
      ...state,
      modals: {
        ...state.modals,
        paymentStatus: {
          ...state.modals.paymentStatus,
          show: action.payload.show,
        },
      },
    };
  },
  [HOME.SET_IS_OPEN_TAKE_INSURANCE_MODAL](state, action) {
    return {
      ...state,
      modals: {
        ...state.modals,
        takeInsurance: {
          ...state.modals.takeInsurance,
          isOpen: action.payload.isOpen,
        },
      },
    };
  },
  [HOME.TOGGLE_SHOW_HIDDE_PILOT_ALERT](state, action) {
    return {
      ...state,
      pilotAlert: {
        ...state.pilotAlert,
        showAlert: action.payload.showAlert,
        alertType: action.payload.alertType,
        severity: action.payload.severity,
      },
    };
  },
  [HOME.CHANGE_LIST_TYPE](state, action) {
    return {
      ...state,
      listType: action.payload.listType,
    };
  },
  [HOME.SET_IS_MARKET_PLACE](state, action) {
    return {
      ...state,
      isMarketPlace: action.payload.isMarketPlace,
    };
  },

  [HOME.GET_PUBLISHED_BIKES](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [HOME.GET_PUBLISHED_BIKES_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      marketplaceFilterFormChanged: false,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination,
      },
    };
  },
  [HOME.GET_PUBLISHED_BIKES_FAIL](state, action) {
    return {
      ...state,
      loading: false,
      error: true,
      message: action.payload.errorMessage,
    };
  },
  [HOME.SET_FORM_FILTER](state, action) {
    state.filterForm = {
      ...state.filterForm,
      ...action.payload.filterForm,
    };
    state.marketplaceFilterFormChanged = true;
  },
  [HOME.RESET_FORM_FILTER](state) {
    state.filterForm = {
      ...initialState.filterForm,
      searchBikfyPublications: state.filterForm.searchBikfyPublications,
    };
  },
  [HOME.SET_STORE_HOME_FORM_FILTER](state, action) {
    state.storeHomeFilterForm = {
      ...state.storeHomeFilterForm,
      ...action.payload.storeHomeFilterForm,
    };
  },
  [HOME.RESET_STORE_HOME_FORM_FILTER](state) {
    state.storeHomeFilterForm = { ...initialState.storeHomeFilterForm };
  },
  [HOME.CHANGE_VEHICLE_TYPE_STORE_FILTER_FORM](state) {
    state.storeHomeFilterForm = {
      ...initialState.storeHomeFilterForm,
      vehicleTypeId: state.storeHomeFilterForm.vehicleTypeId,
    };
  },
  [HOME.SET_FORM_FILTER_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination,
      },
    };
  },
  [HOME.SET_FORM_FILTER_FAIL](state, action) {
    return {
      ...state,
      loading: false,
      error: true,
      message: action.payload.errorMessage,
    };
  },
  [HOME.SET_PAGINATION](state, action) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination,
      },
      loading: true,
    };
  },
  [HOME.SET_STORE_HOME_PAGINATION](state, action) {
    return {
      ...state,
      paginationStoreHome: {
        ...state.paginationStoreHome,
        ...action.payload.pagination,
      },
      loading: true,
    };
  },
  [HOME.RESET_PAGINATION](state) {
    const { total, page, offset } = initialState.pagination;
    state.pagination = {
      ...state.pagination,
      total,
      page,
      offset,
    };
  },
  [HOME.RESET_STORE_HOME_PAGINATION](state) {
    const { total, page, offset } = initialState.paginationStoreHome;
    state.paginationStoreHome = {
      ...state.paginationStoreHome,
      total,
      page,
      offset,
    };
  },
  [HOME.SET_PAGINATION_SUCCESS](state, action) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination,
      },
      loading: false,
    };
  },
  [HOME.SET_PAGINATION_FAIL](state, action) {
    return {
      ...state,
      error: true,
      message: action.payload.errorMessage,
      loading: false,
    };
  },
  [HOME.TOGGLE_SHOW_FILTERS](state, action) {
    state.showFilters = action.payload.showFilters;
  },
  [HOME.SET_SORT_BY](state, action) {
    state.lastSortBy = { ...state.sortBy };
    state.sortBy = {
      field: action.payload.field,
      order: action.payload.orderBy,
    };
  },
  [HOME.SET_STORE_HOME_SORT_BY](state, action) {
    state.storeHomeSortBy = {
      field: action.payload.field,
      order: action.payload.orderBy,
    };
  },
  [HOME.CLEAR_SORT_BY](state, action) {
    /* if (!action.payload.pathType === 'marketplace') { */
    state.sortBy = { ...initialState.sortBy };
    /* } */
  },
  [HOME.CLEAR_STORE_HOME_SORT_BY](state, action) {
    /* if (!action.payload.pathType === 'marketplace') { */
    state.storeHomeSortBy = { ...initialState.storeHomeSortBy };
    /* } */
  },
  [HOME.TOGGLE_SET_SELLER_EMAIL_DIALOG](state, action) {
    state.modals.setSellerEmail.open = action.payload.open;
    state.bikeId = action.payload.bikeId;
    state.transferId = action.payload.transferId;
  },
  [HOME.SET_SELLER_EMAIL](state, action) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
    state.loading = true;
    state.error = false;
    state.formSellerEmailValues.errors = false;
  },
  [HOME.SET_SELLER_EMAIL_SUCCESS](state, action) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...initialState.formSellerEmailValues,
    };
    state.loading = false;
    state.modals.setSellerEmail.open = false;
  },
  [HOME.SET_SELLER_EMAIL_FAIL](state, action) {
    state.loading = false;
    state.message = action.payload.errorMessage;
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
      errors: true,
    };
  },
  [HOME.TOGGLE_REPORT_PUBLISH](state, action) {
    state.modals.reportPublish.open = action.payload.open;
    state.modals.reportPublish.bikeMarketId = action.payload.bikeMarketId;
  },
  [HOME.REPORT_PUBLISH](state, action) {
    state.loading = true;
    state.reportReasonId = action.payload.reportReasonId;
  },
  [HOME.REPORT_PUBLISH_SUCCESS](state, action) {
    state.loading = false;
    state.reportReasonId = initialState.reportReasonId;
    state.modals.reportPublish.open = false;
  },
  [HOME.REPORT_PUBLISH_FAIL](state, action) {
    state.loading = false;
  },
  [HOME.REQUEST_ACCESS](state, action) {
    state.loading = true;
  },
  [HOME.REMEMBER_REGISTER_BIKE](state, action) {
    state.loading = true;
  },
  [HOME.REMEMBER_REGISTER_BIKE_SUCCESS](state, action) {
    state.loading = false;
  },
  [HOME.REMEMBER_REGISTER_BIKE_ERROR](state, action) {
    state.loading = false;
  },
  [HOME.REMEMBER_ACCESS_FOR_BIKE_SUCCESS](state, action) {
    state.loading = false;
  },
  [HOME.REMEMBER_ACCESS_FOR_BIKE_ERROR](state, action) {
    state.loading = false;
  },
  [HOME.REQUEST_ACCESS_SUCCESS](state, action) {
    state.loading = false;
  },
  [HOME.REQUEST_ACCESS_ERROR](state, action) {
    state.loading = false;
  },
  [HOME.REMOVE_STORE_ACCESS](state, action) {
    state.loading = true;
  },
  [HOME.REMOVE_STORE_ACCESS_SUCCESS](state, action) {
    state.loading = false;
  },
  [HOME.REMOVE_STORE_ACCESS_ERROR](state, action) {
    state.loading = false;
  },
  [HOME.EXPORT_TO_CSV_BIKE_DATA_SUCCESS](state, action) {
    state.csvData = action.payload.csvData;
  },
  [HOME.CLEAR_CSV_DATA](state) {
    state.csvData = [];
  },
  [HOME.CLEAR_PILOT_ALERT](state) {
    state.pilotAlert = initialState.pilotAlert;
  },
  [HOME.CLEAR_TRANSFER_ALERT](state) {
    state.transfersPending = initialState.transfersPending;
    state.transfersPendingBuyer = initialState.transfersPendingBuyer;
    state.transfersPendingSeller = initialState.transfersPendingSeller;
  },
  [HOME.SET_GET_ALL_DATA](state, action) {
    state.getAllData = action.payload;
  },
  [HOME.GET_BIKES_FOR_STORE](state) {
    state.loading = true;
  },
  [HOME.GET_BIKES_FOR_STORE_SUCCESS](state, action) {
    state.loading = false;
    action.payload.pagination
      ? (state.pagination = {
        ...state.pagination,
        ...action.payload.pagination,
      })
      : (state.paginationStoreHome = {
        ...state.paginationStoreHome,
        ...action.payload.paginationStoreHome,
      });
  },
  [HOME.GET_BIKES_FOR_STORE_FAIL](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMessage;
  },
  [HOME.SET_PATH_TYPE](state, action) {
    state.pathType = action.payload;
  },
  [HOME.GET_USERS_COUNT_SUCCESS](state, action) {
    state.usersCount = action.payload;
  },
  [HOME.GET_USERS_COUNT_ERROR](state, action) {
    state.usersCount = null;
  },
  [HOME.GET_BIKES_COUNT_SUCCESS](state, action) {
    state.bikesCount = action.payload;
  },
  [HOME.GET_BIKES_COUNT_ERROR](state, action) {
    state.bikesCount = null;
  },
  [HOME.SET_LAST_Y_SCROLL](state, action) {
    state.lastYScroll = action.payload.lastYScroll;
  },
  [HOME.PERSIST_MARKETPLACE_TYPE_LIST](state) {
    state.lastMarketplaceListType = state.listType;
  },
  [HOME.SET_LAST_MARKETPLACE_LIST_TYPE](state) {
    state.listType = state.lastMarketplaceListType;
  },
  [HOME.UPDATE_USER_ALERTED_IN_LANDING](state, action) {
    state.loadingUpdateUserAlertedInLanding = true;
  },
  [HOME.UPDATE_USER_ALERTED_IN_LANDING_SUCCESS](state, action) {
    state.loadingUpdateUserAlertedInLanding = false;
    state.isUserAlertedInLanding = true;
  },
  [HOME.UPDATE_USER_ALERTED_IN_LANDING_ERROR](state, action) {
    state.loadingUpdateUserAlertedInLanding = false;
  },
  [HOME.RESET_USER_ALERTED_IN_LANDING](state) {
    state.isUserAlertedInLanding = false;
  },
});

export default home;
