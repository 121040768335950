// @ts-nocheck
import React from 'react';
// material
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
// styles
// components
// icons
import Bike from '@material-ui/icons/DirectionsBikeOutlined';
import Assignment from '@material-ui/icons/AssignmentOutlined';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StarIcon from '@material-ui/icons/Star';
import PersonIcon from '@material-ui/icons/Person';
import PieChartIcon from '@material-ui/icons/PieChart';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StoreIcon from '@material-ui/icons/Store';
import SearchIcon from '@material-ui/icons/Search';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { PATHS } from 'utils/paths';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-bikfy-main.svg';
import styles from './styles';

const useStyles = makeStyles(styles);

const views = {
  public: [
    { label: 'Mis Bikes', module: PATHS.PILOT, icon: <Bike /> },
    { label: 'Marketplace', module: PATHS.MARKETPLACE, icon: <StorefrontIcon /> },
    { label: 'Informe BIKFY', module: PATHS.SEARCH, icon: <Assignment /> },
    { label: 'Club de beneficios BIKFY', module: PATHS.CLUB_BIKFY, icon: <StarIcon /> },
    // disabled for production
    { label: 'Asegura tu Bike', module: PATHS.INSURABLE, icon: <VerifiedUserIcon /> },
    {
      label: '¿Buscas una Bike?',
      secondaryLabel: 'Te ayudamos',
      module: PATHS.OFFER_FOR_BIKE,
      icon: <SearchIcon />,
    },
  ],
  pilot: [
    { label: 'Mis Bikes', module: PATHS.PILOT, icon: <Bike /> },
    { label: 'Marketplace', module: PATHS.MARKETPLACE, icon: <StorefrontIcon /> },
    { label: 'Informe BIKFY', module: PATHS.SEARCH, icon: <Assignment /> },
    { label: 'Club de beneficios BIKFY', module: PATHS.CLUB_BIKFY, icon: <StarIcon /> },
    // disabled for production
    { label: 'Asegura tu Bike', module: PATHS.INSURABLE, icon: <VerifiedUserIcon /> },
    {
      label: '¿Buscas una Bike?',
      secondaryLabel: 'Te ayudamos',
      module: PATHS.OFFER_FOR_BIKE,
      icon: <SearchIcon />,
    },
  ],

  store: [
    { label: 'Datos', module: PATHS.STORE_DOWNLOAD_BIKE_DATA, icon: <PieChartIcon /> },
    { label: 'Pre-Registrar Bike', module: PATHS.STORE_BIKE_REGISTER, icon: <Bike /> },
    { label: 'Bikes', module: PATHS.STORE, icon: <StoreIcon /> },
    { label: 'Búsqueda Bike BIKFY', module: PATHS.SEARCH, icon: <Assignment /> },
    { label: 'Marketplace', module: PATHS.MARKETPLACE, icon: <StorefrontIcon /> },
    { label: 'Datos Bancarios', module: PATHS.STORE_BANK_DATA, icon: <AccountBalanceIcon /> },
  ],

  ambassador: [{ label: 'Registrar Bike', module: PATHS.AMBASSADOR, icon: <Bike /> }],

  admin: [
    { label: 'Bikes', module: PATHS.ADMIN, icon: <Bike /> },
    { label: 'Importar excel', module: PATHS.PAYMENTS_IMPORT, icon: <Assignment /> },
    { label: 'Exportar excel', module: PATHS.PAYMENTS_EXPORT, icon: <Assignment /> },
    {
      label: 'Importar ventas de tienda ',
      module: PATHS.IMPORT_SALES,
      icon: <SystemUpdateAltIcon />,
    },
  ],
};

const CustomLink = (props) => {
  const { view, toggleDrawer } = props;

  const CustomLink = React.useMemo(
    () => React.forwardRef((linkProps, ref) => <Link ref={ref} to={view.module} {...linkProps} />),
    [view.module],
  );

  return (
    <ListItem
      button
      style={{ display: view.isHide ? 'none' : 'flex' }}
      component={CustomLink}
      onClick={() => toggleDrawer(view.module)}
    >
      <ListItemIcon>{view.icon}</ListItemIcon>
      <ListItemText primary={view.label} secondary={view?.secondaryLabel || ''} />
    </ListItem>
  );
};

const SideBar = ({ user, isOpen, toggleDrawer }) => {
  const classes = useStyles();

  const menu = (views[user?.user?.type] || views.public).map((view) => (
    <CustomLink key={`menu-${view.label}`} view={view} toggleDrawer={toggleDrawer} />
  ));

  return (
    <Drawer
      classes={{ root: classes.root, paperAnchorLeft: classes.paperAnchorLeft }}
      open={isOpen}
      onClose={() => toggleDrawer()}
    >
      <Typography variant="h6" className={classes.titles}>
        <img className={classes.icon} src={logo} alt="" />
      </Typography>
      <Divider />
      <List>{menu}</List>
      <Divider />
    </Drawer>
  );
};

export default SideBar;
