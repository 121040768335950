/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { PATHS } from 'utils/paths';
import styles from './styles';

const useStyles = makeStyles(styles);

const SuccessPay = (props) => {
  const { bikeDetails, history, isBikfyPay } = props;
  const classes = useStyles();

  const { generalContainer, paperContainer } = classes;

  const { brand, model, serial } = bikeDetails;

  const handleClickShowBikeButton = () => history.push(PATHS.PILOT);

  return (
    <Grid className={`${generalContainer}`}>
      <Paper className={`${paperContainer}`}>
        <Box className="title-container">
          <Typography className="title">🎉 ¡FELICITACIONES! 🎉</Typography>
        </Box>
        <Box className="message-container">
          <Typography className="paragraph">
            {isBikfyPay ? 'El pago y' : 'La'} transferencia de la Bike marca <b>{brand.name}</b>,
            modelo <b>{model.name}</b>
            {serial ? ` y código de serie ${serial}` : ''} ha sido
            <span className="bikfy-color"> realizada con éxito </span>.
          </Typography>
          {/* SHOW ONLY ON BIKFY PAY */}
          {isBikfyPay && (
            <Typography className="paragraph">
              Tu pago se le abonará al vendedor dentro de los primeros 5 días hábiles.
            </Typography>
          )}
        </Box>
        <Box className="button-container">
          <Button
            id="id_button_see_my_bike"
            variant="contained"
            color="primary"
            className="show-bike-button"
            id="btn-see-my-bike"
            onClick={handleClickShowBikeButton}>
            Ver mi Bike
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
};

SuccessPay.propTypes = {
  bikeDetails: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  isBikfyPay: PropTypes.bool.isRequired,
};

export default SuccessPay;
