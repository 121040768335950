/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { formatTaxNumber } from 'utils/functions';

// MATERIAL
import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const BankDataForm = (props) => {
  const {
    className,
    banks,
    accountTypes,
    submitButtonRef,
    setBankData,
    stateFormValues,
    isStoreBankDataPage,
  } = props;

  const [formValues, setFormValues] = useState({
    userName: '',
    rut: '',
    email: '',
    bankId: 0,
    accountTypeId: 0,
    accountNumber: '',
  });

  console.log({
    stateFormValues,
  });

  useEffect(() => {
    if (stateFormValues.bankId !== 0) {
      setFormValues((prevState) => ({ ...prevState, ...stateFormValues }));
    }
  }, []);

  const { userName, rut, email, bankId, accountTypeId, accountNumber } = formValues;

  // TODO: add use callback
  const onChangeInputs = (event) => {
    const { value, name } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: name === 'rut' ? formatTaxNumber(value) : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setBankData(formValues);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <TextField
        label={isStoreBankDataPage ? 'Nombre' : 'Nombre completo'}
        variant="outlined"
        className="formInput"
        onChange={onChangeInputs}
        name="userName"
        value={userName}
      />
      <TextField
        label="RUT"
        variant="outlined"
        className="formInput"
        onChange={onChangeInputs}
        name="rut"
        value={rut}
      />
      <TextField
        label="Correo"
        variant="outlined"
        className="formInput"
        onChange={onChangeInputs}
        name="email"
        value={email}
      />
      <FormControl className="formInput" variant="outlined">
        <InputLabel id="select-id-1">Banco</InputLabel>
        <Select
          labelId="select-id-1"
          value={bankId}
          onChange={onChangeInputs}
          label="Banco"
          name="bankId">
          <MenuItem value={0}>Selecciona una opcion ...</MenuItem>
          {banks.map((bank) => (
            <MenuItem key={bank.id} value={bank.id}>
              {bank.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="formInput" variant="outlined">
        <InputLabel id="select-id-2">Tipo Cuenta</InputLabel>
        <Select
          labelId="select-id-2"
          value={accountTypeId}
          onChange={onChangeInputs}
          label="Tipo Cuenta"
          name="accountTypeId">
          <MenuItem value={0}>Selecciona una opcion ...</MenuItem>
          {accountTypes.map((accountType) => (
            <MenuItem key={accountType.id} value={accountType.id}>
              {accountType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="N° de Cuenta"
        variant="outlined"
        className="formInput"
        onChange={onChangeInputs}
        name="accountNumber"
        value={accountNumber}
      />
      <input type="submit" value="TEST" style={{ display: 'none' }} ref={submitButtonRef} />
    </form>
  );
};

BankDataForm.propTypes = {
  className: PropTypes.string,
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submitButtonRef: PropTypes.shape({}).isRequired,
  setBankData: PropTypes.func.isRequired,
  stateFormValues: PropTypes.shape({}).isRequired,
};

BankDataForm.defaultProps = {
  className: '',
};

export default BankDataForm;
