import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import logoBci from 'commons/assets/logo-bci.png';
import logoFullwheels from 'commons/assets/providers/logoFullwheels.png';
import { useHistory } from 'react-router-dom';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function InformativeModal(props) {
  const history = useHistory();
  const classes = useStyles();
  const { open, closeInformativeModal } = props;
  return (
    <Dialog open={!open} onClose={() => closeInformativeModal()} className={classes.dialog} maxWidth="sm" fullWidth>
      <DialogContent
        style={{
          textAlign: 'center',
          backgroundColor: '#3BA3B2',
          color: 'white',
        }}
      >
        <img src={logoFullwheels} alt="Logo Fullwheels" style={{ width: '50%' }} />
        <DialogContentText>
          <Typography style={{ color: 'white' }}>
            NUEVA MEMBRESÍA PARA EL PARQUE DE MOTOS FULLWHEELS
            POR TAN SOLO $22.000 AL MES. 🚲
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center', backgroundColor: '#3BA3B2', color: 'white' }}>
        <Grid container justifyContent="center">
          <Button
            style={{ backgroundColor: '#323E48' }}
            onClick={() => {
              closeInformativeModal();
              history.push('/club-bikfy');
            }}
            color="primary"
            variant="contained"
          >
            Contratar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
