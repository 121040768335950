import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomTitleDefault from 'commons/components/CustomTitleDefault';
import {
  Box,
  Card,
  CardContent,
  useMediaQuery,
  Typography,
  withStyles,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory, Link, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Carousel from 'react-material-ui-carousel';

import { userInfo } from 'utils/reportBikfy';
import { formatDateToString } from 'utils/functions';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DisableDialog from 'screens/Admin/components/DisableDialog/DisableDialog';
import { BIKE } from 'screens/Bike/reducer';
import BikfyReport from '../BikfyReport/BikfyReport';

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  title: {
    marginTop: 20,
  },
  wrapCarousel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 390,
  },
  imgCarousel: {
    maxWidth: '100%',
    maxHeight: 390,
  },
});

const SearchSummary = (props) => {
  const {
    params,
    searchBike,
    viewMaintenance,
    bike = {},
    buyer = {},
    user,
    styles,
    requestAccess,
    adminState,
    objects,
    actions,
    handleToggleDisableBike,
    handleOpenDisableBikeModal,
    openDisableBikeModal,
  } = props;

  const { buttonsContainer } = styles;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const location = useLocation();

  const isAdmin = user.type === 'admin';
  const isStore = user.type === 'store';

  const photos = (bike.documents || []).filter((file) => {
    if (['own_invoice', 'bikfy_sale'].includes(file.type)) {
      return isAdmin;
    }
    return true;
  });

  const handleToggleDisableBikeChild = ({ formValues, isDisable }) => {
    handleToggleDisableBike({ formValues, isDisable, bikeId: bike?.id });
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleClickRequestAccessButton = (event) => {
    requestAccess({ transferId: bike.transferId });
  };

  const handleCloseDialog = () => {
    handleOpenDisableBikeModal(false);
  };

  useEffect(() => {
    searchBike(params);
  }, []);

  const handleGoBack = () => {
    if (location?.state?.fromSearchView) {
      return history.push('/search');
    }
    return history.goBack();
  };

  const Row = ({ name, value }) => (
    <StyledTableRow key={name}>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">
        {name === 'Denuncia' ? (
          <strong style={{ color: value ? '#E64678' : 'green' }}>{value ? 'Si' : 'No'}</strong>
        ) : (
          value || 'No especificado'
        )}
      </TableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Grid container xs={12} sm={12}>
        <Grid container xs={12}>
          <Grid item xs={6} sm={6}>
            <IconButton aria-label="upload picture" component="span" onClick={handleGoBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          {isAdmin && (
            <Grid container justifyContent="flex-end" xs={6} sm={6}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => handleOpenDisableBikeModal(true)}>
                {bike.disabled === 'No' ? 'INHABILITAR' : 'HABILITAR'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CustomTitleDefault title="Informe BIKFY" />
      {(isAdmin || isStore) && (
        <>
          <Typography variant="h6" color="textSecondary">
            Información del usuario
          </Typography>

          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {userInfo.map(
                  (info) =>
                    info.shows.includes(user.type) && (
                      <Grid item xs={12} sm={6} key={info.key}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <Grid item xs={2} sm={1}>
                            {info.icon}
                          </Grid>
                          <Grid item xs>
                            <Typography color="textSecondary">{info.label}</Typography>
                            <Typography variant="body1">
                              {info.key === 'birthdate'
                                ? formatDateToString(buyer[info.key])
                                : buyer[info.key]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ),
                )}
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
      {isAdmin && (
        <Typography variant="h6" color="textSecondary" className={classes.title}>
          Información de la Bike
        </Typography>
      )}
      {photos.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Carousel interval={10000}>
              {photos.map((item) => {
                const { file } = item;

                const isPDF = file.name.split('.').pop().toLowerCase() === 'pdf';

                return (
                  <div key={item.id} className={classes.wrapCarousel}>
                    <>
                      {!isPDF ? (
                        <img src={file.url} alt={file.name} className={classes.imgCarousel} />
                      ) : (
                        <iframe
                          title={file.name}
                          src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                          height="390"
                          width="300"
                          frameBorder="0"
                        />
                      )}
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          window.open(file.url, '_blank');
                        }}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </>
                  </div>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      )}
      <Card>
        <CardContent>
          <BikfyReport bike={bike} userType={user.type} />
        </CardContent>
      </Card>
      <Grid container spacing={3} alignItems="center" style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={12}>
          <Grid container className={`${buttonsContainer}`}>
            <Grid item xs={12} sm={1} className="button-container">
              <IconButton
                aria-label="upload picture"
                component="span"
                onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={4} className="button-container">
              {bike?.saleDocument && (
                <Link to={`/transfer/${bike.saleDocument}/contract`}>
                  <Button color="primary">Ver contrato de compra/venta</Button>
                </Link>
              )}
            </Grid>
            {isStore && !bike.isAccessByUser && (
              <Grid item xs={12} sm={3} align="center" className="button-container">
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleClickRequestAccessButton}
                  style={{
                    color: '#fff',
                    backgroundColor: '#303e48',
                  }}>
                  Solicitar acceso
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={3} align="center" className="button-container">
              <Button
                type="submit"
                variant="contained"
                onClick={() => viewMaintenance()}
                style={{
                  color: '#fff',
                  backgroundColor: '#303e48',
                  marginTop: 7,
                }}>
                Libro Taller
              </Button>
            </Grid>
            {isAdmin && !isMobileScreen && (
              <Grid container xs={12} sm={4} align="center" justifyContent="flex-end">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => handleOpenDisableBikeModal(true)}>
                  {bike.disabled === 'No' ? 'INHABILITAR' : 'HABILITAR'}
                </Button>
              </Grid>
            )}
            {isAdmin && isMobileScreen && (
              <Grid
                container
                xs={12}
                sm={4}
                align="center"
                justifyContent="center"
                style={{ marginTop: 15 }}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => handleOpenDisableBikeModal(true)}>
                  {bike.disabled === 'No' ? 'INHABILITAR' : 'HABILITAR'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DisableDialog
        open={openDisableBikeModal}
        handleToggleShowDisableDialog={handleCloseDialog}
        disabledReasons={objects.disabledReasons}
        disableFormDialog={adminState.disableFormDialog}
        handleToggleDisableBike={handleToggleDisableBikeChild}
        isDisable={!(bike.disabled === 'No')}
        actions={actions}
        disabledReasonIds={objects?.bikes[adminState.disableDialog.bikeId]?.disabledBikes?.map(
          (disabledBike) => disabledBike.disabledReasonId,
        )}
      />
    </>
  );
};

export default SearchSummary;
