import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  transfer: {
    textDecoration: 'none',
    color: '#45C6D9',
  },
  report: {
    textDecoration: 'none',
    color: '#E64678',
  },
  edit: {
    textDecoration: 'none',
    color: '#000000',
  },
  maintenance: {
    textDecoration: 'none',
    color: '#303e48',
  },
}));

const ITEM_HEIGHT = 48;

const OptionButton = ({
  title, transferId, bikeFilter, reported, bikeId, history
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrevent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const goToTransfer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/bike/${transferId}/transfer`);
  };

  const goToMaintenance = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/maintenance/${bikeId}`);
  };

  const goToReport = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`bike/${transferId}/report`);
  };

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={9}>
          {title}
        </Grid>
        {bikeFilter === 1
          && (
            <Grid item xs={3} align="right">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Grid>
          )}
      </Grid>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        onClick={handlePrevent}
      >
        {!reported
          && (
            <div>
              <MenuItem onClick={goToTransfer}>
                {/* <Link className={classes.transfer} to={`bike/${transferId}/transfer`}> */}
                TRANSFERIR
                {/* </Link> */}
              </MenuItem>
              <MenuItem onClick={goToMaintenance}>
                {/* <Link className={classes.maintenance} to={`maintenance/${bikeId}`}> */}
                TALLER
                {/* </Link> */}
              </MenuItem>
              <MenuItem onClick={goToReport}>
                {/* <Link className={classes.report} to={`bike/${transferId}/report`}> */}
                DENUNCIAR
                {/* </Link> */}
              </MenuItem>
            </div>
          )}
        <MenuItem>
          <Link className={classes.edit} to={`bike/${transferId}`}>
            EDITAR
          </Link>
        </MenuItem>
      </Menu>

    </div>
  );
};

export default OptionButton;
