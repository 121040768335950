export const FIRST_STAGE_TRANSFER_ID = 1;
export const SECOND_STAGE_TRANSFER_ID = 2;
export const THIRD_STAGE_TRANSFER_ID = 3;
export const FOURTH_STAGE_TRANSFER_ID = 4;

export const CREDIT_CARD_PAYMENT = 'credit_card';
export const CREDIT_CARD_PAYMENT_WITH_FEES = 'credit_card_with_fees';
export const EXTERNAL_PAYMENT = 'external_payment';

export const TOTAL_SELLER_FLOW_STEPS = 4;

export const STORE_BIKE_STATUS = {
  1: 'my_registers',
  2: 'my_pending_registers',
  3: 'with_granted_access',
  4: 'with_pending_access',
  5: 'published_by_me',
  6: 'published_by_pilot',
  7: 'transferred_bikes',
  8: 'reported_bikes',
  9: 'my_pending_transfers',
};

export const specialCharFormat = /[!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?~]/;
export const specialCharFormatForBikes = /[!@#$%^&*_\=\[\]{};:\\|<>?~]/;
export const serialSpecialCharFormat = /[=\[\]{}\\<>?~]/;
