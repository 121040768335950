import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import xlsx from 'xlsx';
import { composedComponent } from '../../utils/functions';
import adminSaleActions from './actions';
import adminSaleSaga from './saga';

import styles from './styles';

const AdminSale = (props) => {
  const { actions, classes, adminSale: adminSaleState } = props;

  const { loading, importSaleReport } = adminSaleState;

  const [sales, setSales] = useState([]);

  const uploadButtonRef = useRef(null);

  const convertSheetToJSON = (file) => {
    Papa.parse(file, {
      header: true,
      /* dynamicTyping: true, */
      encoding: 'ISO-8859-1',
      complete(results) {
        setSales(results.data.filter((d) => d.store_id));
      },
    });
  };

  useEffect(() => {
    const reportToCsv = [];
    if (importSaleReport.length > 0) {
      importSaleReport.forEach((report) => {
        if (!report.ok) {
          reportToCsv.push({ ...sales[report.index], observacion: report.message });
        }
      });
      setSales([]);

      if (!reportToCsv.length) return;

      const worksheet = xlsx.utils.json_to_sheet(reportToCsv);
      const csv = xlsx.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csv], {
        type: 'text/csv',
      });
      // @ts-ignore
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    }
  }, [importSaleReport.length]);
  const handleChangeButtonFile = (event) => {
    const { files } = event.target;
    const file = files[0];

    convertSheetToJSON(file);
  };

  const handleLoadExcel = () => uploadButtonRef.current.click();

  const handleSave = () => {
    actions.uploadSalesExcel({ sales });
    uploadButtonRef.current.value = '';
  };

  return (
    <Grid container component={Paper} className={classes.generalContainer}>
      <Grid className={classes.titleContainer} item xs={12}>
        <Typography className="title">
          <span className="bikfy-color">Importa </span>
          las ventas de las tiendas
        </Typography>
        <Alert severity="info">
          Ingresa el excel para poder previsualizar la información antes cargar
        </Alert>
      </Grid>

      <Grid
        className={classes.buttonsContainer}
        container
        item
        xs={12}
        justifyContent="space-between">
        <Grid item>
          <input
            accept=".csv,.xlsx,.xls"
            type="file"
            ref={uploadButtonRef}
            style={{ display: 'none' }}
            onChange={handleChangeButtonFile}
          />
          <Button variant="contained" color="primary" onClick={handleLoadExcel}>
            Subir excel
          </Button>
        </Grid>
        {sales.length > 0 && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {loading ? (
                <>
                  <CircularProgress size={24} color="secondary" /> Cargando
                </>
              ) : (
                ' Guardar Ventas'
              )}
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id tienda</TableCell>
                <TableCell>Categoría</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell>Versión</TableCell>
                <TableCell>Talla</TableCell>
                <TableCell>Año</TableCell>
                <TableCell>Aro</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Fecha de venta</TableCell>
                <TableCell>Valor boleta</TableCell>
                <TableCell>RUT vendedor</TableCell>
                <TableCell>Número folio</TableCell>
                <TableCell>Código de serie</TableCell>
                <TableCell>RUT cliente</TableCell>
                <TableCell>Email cliente</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.map((sale, index) => (
                <TableRow key={index}>
                  <TableCell>{sale.store_id}</TableCell>
                  <TableCell>{sale.category}</TableCell>
                  <TableCell>{sale.brand}</TableCell>
                  <TableCell>{sale.model}</TableCell>
                  <TableCell>{sale.version}</TableCell>
                  <TableCell>{sale.size}</TableCell>
                  <TableCell>{sale.year}</TableCell>
                  <TableCell>{sale.rim}</TableCell>
                  <TableCell>{sale.color}</TableCell>
                  <TableCell>{sale.sold_date}</TableCell>
                  <TableCell>{sale.price}</TableCell>
                  <TableCell>{sale.seller_rut}</TableCell>
                  <TableCell>{sale.invoice_number}</TableCell>
                  <TableCell>{sale.serial}</TableCell>
                  <TableCell>{sale.client_rut}</TableCell>
                  <TableCell>{sale.client_email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default composedComponent(AdminSale, adminSaleSaga, {
  saga: 'adminSaleSaga',
  states: ['adminSale'],
  actions: [adminSaleActions],
  middlewares: [withStyles(styles)],
});
