/* eslint-disable no-param-reassign */
import { LOGIN } from 'screens/Login/reducer';
import { createReducer, createAction } from 'utils/reducer';
import { normalizeObj } from 'utils/functions';
import { PROFILE } from 'screens/Profile/reducer';

// GLOBAL
export const GET_USER = 'app/GET_USER';
export const GET_USER_SUCCESS = 'app/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'app/GET_USER_FAIL';
export const GET_STATE_FROM_API = 'app/GET_API_FROM_STATE';
export const GET_STATE_FROM_API_SUCCESS = 'app/GET_API_FROM_STATE_SUCCESS';
export const DELETE_STATE_FROM_API_SUCCESS = 'app/DELETE_STATE_FROM_API_SUCCESS';
export const LOG_OUT = 'app/LOG_OUT';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const RESET_OBJECT = 'app/RESET_OBJECT';
export const ADD_LOADING = 'app/ADD_LOADING';
export const REMOVE_LOADING = 'app/REMOVE_LOADING';
export const CLEAR_OBJECTS_STATE = 'app/CLEAR_OBJECTS_STATE';
export const CLEAR_LAST_MARKETPLACE_ORDER = 'app/CLEAR_LAST_MARKETPLACE_ORDER';
export const CLEAR_SINGLE_OBJECT = 'app/CLEAR_SINGLE_OBJECT';

export const appActions = {
  // api fetch
  logOut: createAction(LOG_OUT),
  getUser: createAction(GET_USER),
  setUser: createAction(GET_USER_SUCCESS, 'payload'),
  // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER, 'payload'),
  setError: createAction(SET_ERROR, 'payload'),
  setSuccess: createAction(SET_SUCCESS, 'payload'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  resetObject: createAction(RESET_OBJECT, 'payload'),
  addLoading: createAction(ADD_LOADING, 'payload'),
  removeLoading: createAction(REMOVE_LOADING, 'payload'),
  clearObjectsState: createAction(CLEAR_OBJECTS_STATE, 'payload'),
  clearLastMarketplaceOrder: createAction(CLEAR_LAST_MARKETPLACE_ORDER),
  clearSingleObject: createAction(CLEAR_SINGLE_OBJECT, 'payload'),
};

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  loading: [],
  objects: {
    comunes: {},
    brands: {},
    models: {},
    categories: {},
    rims: {},
    sizes: {},
    bikes: {},
    transfers: {},
    documents: {},
    files: {},
    report: {},
    currencies: {},
    maintenances: {},
    maintenancesTypes: {},
    bike: {},
    buyer: {},
    comissions: {},
    bikesMarkets: {},
    regions: {},
    disabledReasons: {},
    deletedPublicationReasons: {},
    useTypes: {},
    vehicleTypes: {},
    motorbikeCategories: {},
    motorbikeBrands: {},
    motorbikeModels: {},
    engineCapacities: {},
    motorbikes: {},
  },
  order: {
    comunes: [],
    brands: [],
    models: [],
    categories: [],
    rims: [],
    transfers: [],
    currencies: [],
    maintenances: [],
    maintenancesTypes: [],
    vehicleTypes: [],
    motorbikeCategories: [],
    motorbikeBrands: [],
    motorbikeModels: [],
  },
  lastMarketPlaceOrder: {},
  user: null,
  loaders: {},
  device: {},
  deviceError: '',
  searchPrinterLoading: false,
};

const mergeObjects = (state, action) => {
  console.log(action);
  const newObjects = {};
  const newOrder = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects?.[key],
      ...(action.payload.objects?.[key] || {}),
    };
  });

  if (action.payload.order) {
    state.order = { ...state.order, ...action.payload.order };
  }
  state.objects = newObjects;
  console.log(newObjects);
};

/**
 *
 * @param {*} state
 * @param {*} action
 * action is similar to { deleted: { documents: [1, 2, 3] } }
 */
const deleteObject = (state, action) => {
  const newObjects = {};
  Object.keys(action.payload.deleted).forEach((key) => {
    newObjects[key] = normalizeObj(
      Object.values(state.objects[key]).filter(
        (item) => !action.payload.deleted[key].includes(item.id),
      ),
    );
  });
  state.objects = { ...state.objects, ...newObjects };
};

const app = createReducer(initialState, {
  [CLEAR_OBJECTS_STATE](state, action) {
    const { pathType } = action.payload;
    if (pathType === 'marketplace') {
      state.lastMarketPlaceOrder = { ...state.order };
      state.lastMarketPlaceObjects = { ...state.objects };
    }
    state.objects = {
      ...initialState.objects,
      useTypes: state.objects.useTypes,
      bike: state.objects.bike,
      transfers: state.objects.transfers,
    };
    state.order = { ...initialState.order };
  },
  [CLEAR_LAST_MARKETPLACE_ORDER](state) {
    state.lastMarketPlaceOrder = { ...initialState.lastMarketPlaceOrder };
    state.lastMarketPlaceObjects = { ...initialState.objects };
  },
  [PROFILE.GET_STATE_FROM_API_COMUNES_SUCCESS]: mergeObjects,
  [GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [DELETE_STATE_FROM_API_SUCCESS]: deleteObject,
  [LOGIN.LOGIN_USER_SUCCESS](state, action) {
    state.user = action.payload;
    state.loadingUser = false;
    state.objects = { ...initialState.objects };
    state.order = { ...initialState.order };
  },
  [LOGIN.LOGIN_USER_FAIL](state) {
    state.loadingUser = false;
    state.objects = { ...initialState.objects };
    state.order = { ...initialState.order };
  },
  [GET_USER_SUCCESS](state, action) {
    state.user = action.payload;
    state.loadingUser = false;
  },
  [GET_USER](state, action) {
    state.loadingUser = true;
  },
  [GET_USER_FAIL](state, action) {
    state.user = null;
    state.loadingUser = false;
  },
  [LOG_OUT](state, action) {
    state.user = null;
  },
  [TOGGLE_DRAWER](state, action) {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  [TOGGLE_ERROR](state, action) {
    state.error = false;
    state.errorMsg = '';
  },
  [SET_ERROR](state, action) {
    state.error = true;
    state.errorMsg = action.payload.errorMsg;
  },
  [TOGGLE_SUCCESS](state, action) {
    state.success = false;
    state.successMsg = !state.successMsg;
  },
  [SET_SUCCESS](state, action) {
    state.success = true;
    state.successMsg = action.payload;
  },
  [SET_LOADER](state, action) {
    state.loaders[action.id] = action.state;
  },
  [RESET_OBJECT](state, action) {
    state.order[action.payload] = initialState.order[action.payload];
    state.objects[action.payload] = initialState.objects[action.payload];
  },
  [ADD_LOADING](state, action) {
    state.loading = [...state.loading, action.payload];
  },
  [REMOVE_LOADING](state, action) {
    state.loading = state.loading.filter((l) => l !== action.payload);
  },
  [CLEAR_SINGLE_OBJECT](state, action) {
    state.objects[action.payload] = initialState.objects[action.payload];
  },
});

export default app;
