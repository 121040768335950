import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { formatNumber } from 'utils/functions';
import documentsType from 'utils/documentsType';

const TransferSummary = ({ amount, buyer, seller, bike, handleOpenModal, storeSeller }) => {
  const defaultValue = '___';
  const documents = bike.documents?.filter((d) => d.type !== 'serial');
  const documentBike = documents?.length ? documents[0].type : 'notDocument';
  const sellerName = storeSeller
    ? `${storeSeller?.legalRepresentative?.name} ${storeSeller?.legalRepresentative?.firstLastName} ${storeSeller?.legalRepresentative?.secondLastName}`
    : `${seller?.name} ${seller?.firstLastName} ${seller?.secondLastName}`;

  const list1 = [
    {
      name: 'Nombre Vendedor',
      value: sellerName,
    },
    {
      name: 'R.U.T Vendedor',
      value: storeSeller ? storeSeller?.legalRepresentative?.taxNumber : seller?.taxNumber,
    },
    {
      name: 'Nombre Comprador',
      value: `${buyer.name ?? defaultValue} ${buyer.firstLastName ?? defaultValue} ${
        buyer.secondLastName ?? defaultValue
      }`,
    },
    { name: 'R.U.T Comprador', value: buyer.taxNumber ?? defaultValue },
  ];

  let list2 = [
    { id: 0, name: 'Categoría', value: bike.category?.name },
    { id: 1, name: 'Marca', value: bike.brand?.name },
    { id: 2, name: 'Modelo', value: bike.model?.name },
    { id: 3, name: 'Año', value: bike.year },
  ];

  const bicycleList = [
    { id: 4, name: 'Talla', value: bike.size?.name },
    { id: 5, name: 'Aro', value: bike.rim?.name },
    { id: 6, name: 'Código de Serie', value: bike.serial },
  ];
  const motorbikeList = [
    { id: 4, name: 'Número de motor', value: bike?.motorbike?.engineSerial },
    { id: 5, name: 'Número de chasis', value: bike?.motorbike?.chassisSerial },
    { id: 6, name: 'Cilindraje motor', value: bike?.motorbike?.engineCapacity?.capacity },
  ];

  if (bike?.isBicycle) {
    list2 = list2.concat(bicycleList);
    list2.push({
      id: 7,
      name: 'Foto factura/boleta o código de serie',
      value: documentsType[documentBike],
    });
  } else {
    list2 = list2.concat(motorbikeList);
    list2.push({
      id: 7,
      name: 'Foto factura/boleta o código de serie',
      value: documentsType[documentBike],
    });
  }

  return (
    <Card variant="outlined">
      <CardHeader
        style={{ textAlign: 'center' }}
        title={
          <Typography component="h4" color="textSecondary" style={{ fontWeight: '700' }}>
            Resumen Transferencia
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            {list1.map((l, key) => (
              <Typography variant="subtitle1" color="textSecondary" key={key}>
                <Typography component="span" style={{ fontWeight: '500' }}>
                  {l.name}:{' '}
                </Typography>
                {l.value}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            {list2.map((l, key) => (
              <Typography variant="subtitle1" color="textSecondary" key={key}>
                <Typography component="span" style={{ fontWeight: '500' }}>
                  {l.name}:{' '}
                </Typography>
                {l.value}{' '}
                {l.id === 7 && (
                  <a href="#" onClick={handleOpenModal}>
                    (Ver)
                  </a>
                )}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textSecondary">
              <Typography component="span" style={{ fontWeight: '500' }}>
                Precio de venta ($):{' '}
              </Typography>
              {formatNumber(amount)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransferSummary;
