import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OptionButton from 'commons/components/OptionButton';
import { Link } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import BikeImage from 'screens/Home/components/BikeImage';
import {
  Tooltip,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Typography,
  ButtonGroup,
} from '@material-ui/core';
import Categories from '../../../utils/categories';

const useStyles = makeStyles((theme) => ({
  margin: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  marginIconBtn: {
    margin: theme.spacing(1),
  },
  buttonStyle: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  editButton: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  optionButton: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cardContent: {
    paddingRight: theme.spacing(4),
  },
  linkButton: {
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
  },
}));

const validate = (value) => value || 'No especificado';

const BikeCard = ({
  bike,
  brandId,
  brand,
  model,
  category,
  size,
  history,
  openModal,
  transferId = '#',
  bikeFilter = 1,
  onDisableBike,
  handleToggleShowDisableDialog,
  motorbike,
}) => {
  const {
    id: bikeId, year, color, serial, reported, dataComplete, transfer,
  } = bike;
  const classes = useStyles();

  const onClickDisableBikeButton = (event) => {
    event.stopPropagation();
    // console.log('SE ABRE MODAL');
    handleToggleShowDisableDialog({ bikeId: bike.id });
    // onDisableBike(bikeId);
  };

  const handleOnClickCard = (event) => {
    if (bike.isBicycle) {
      history.push(`/search/${brandId}/${serial}`);
    } else {
      history.push(`/search/${brandId}/${motorbike[0].chassisSerial}`);
    }
  };

  return (
    <Card
      style={{ cursor: 'pointer' }}
      onClick={
        handleOnClickCard /* () => {
        history.push(`/search/${brandId}/${serial}`);
      } */
      }
    >
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3} align="center">
            {category ? (
              bikeFilter === 1 ? (
                <BikeImage category={category} reported={reported} openModal={openModal} />
              ) : (
                <img src={Categories[category]} alt="" style={{ width: '100%' }} />
              )
            ) : (
              <Link className={classes.link} to={`/bike/${transferId}`}>
                <BlockIcon
                  style={{
                    width: '70%',
                    height: '70%',
                  }}
                />
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Typography component="h5" variant="h4" className={classes.optionButton}>
              <OptionButton
                bikeId={bikeId}
                title={`${brand} ${model || ''}`}
                transferId={transferId}
                bikeFilter={bikeFilter}
                reported={!(!reported && dataComplete)}
              />
            </Typography>
            <Typography component="h5" variant="h5" className={classes.editButton}>
              {bikeFilter === 1 ? (
                <Link className={classes.link} to={`/bike/${transferId}`}>
                  {`${brand} - ${model || ''}`}
                </Link>
              ) : (
                `${brand} - ${model || ''}`
              )}
            </Typography>
            {bike?.isBicycle ? (
              <Typography variant="subtitle1" color="textSecondary">
                Código de serie:
                {' '}
                {validate(serial)}
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  Número de chasis:
                  {' '}
                  {validate(motorbike[0]?.chassisSerial)}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Número de motor:
                  {' '}
                  {validate(motorbike[0]?.engineSerial)}
                </Typography>
              </>
            )}

            <Typography variant="subtitle1" color="textSecondary">
              {transfer?.buyer?.name}
              {' '}
              {transfer?.buyer?.lastName}
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              RUT:
              {' '}
              {validate(transfer?.buyer?.taxNumber)}
            </Typography>
          </Grid>
          {bikeFilter === 1 && (
            <Grid item xs={12} sm={3} md={2} className={classes.buttonStyle}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    !(!reported && dataComplete)
                    || (!bike.isBicycle ? !motorbike[0]?.chassisSerial : false)
                  }
                >
                  ver
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{ marginTop: '10px' }}
                  disabled={!(!reported && dataComplete)}
                  onClick={onClickDisableBikeButton}
                >
                  {bike.disable ? 'habilitar' : 'inhabilitar'}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default BikeCard;
