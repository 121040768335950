// const { takeLatest, spawn } = require('redux-saga/effects');
import { takeLatest, spawn, call, put } from 'redux-saga/effects';
import { getItemInStorage } from 'utils/functions';
import apiRequest from '../../utils/api';
import adminPayTypes from './adminPay.types';

function* getPendingTransfer() {
  yield takeLatest(adminPayTypes.GET_PENDING_TRANSFER, function* getPendingTransferGenerator() {
    const response = yield apiRequest(
      'api/v1/transfers/create-excel/1',
      { method: 'get' },
      true,
      true,
    );

    if (!response.error) {
      const { csvData } = response;
      yield put({ type: adminPayTypes.GET_PENDING_TRANSFER_SUCCESS, payload: { csvData } });
    } else {
      // TODO: revisar bien esto*
      yield put({
        type: adminPayTypes.GET_PENDING_TRANSFER_FAILURE,
        payload: { errorMessage: response.errorMessage },
      });
    }
  });
}

function* uploadExcel() {
  yield takeLatest(adminPayTypes.UPLOAD_EXCEL, function* uploadExcelGenerator(action) {
    const { payload } = action;

    const session = getItemInStorage('user');

    const formData = new FormData();
    formData.append('file', payload.file);

    const response = yield apiRequest(
      'api/v1/transfers/upload-excel/1',
      {
        headers: {
          Authorization: `Bearer ${session && session.token}`,
        },
        method: 'post',
        body: formData,
      },
      false,
    );

    if (!response.error) {
      const { payedTransfers } = response;
      yield put({ type: adminPayTypes.UPLOAD_EXCEL_SUCCESS, payload: { payedTransfers } });
    } else {
      yield put({
        type: adminPayTypes.UPLOAD_EXCEL_FAILURE,
        payload: { errorMessage: response.errorMessage },
      });
    }
  });
}

function* sendPaymentEmailsToPerform() {
  yield takeLatest(
    adminPayTypes.SEND_PAYMENT_EMAILS_TO_PERFORM,
    function* getPendingTransferGenerator() {
      const response = yield apiRequest('api/v1/transfers/create-excel/1', { method: 'get' }, true);

      if (!response.error) {
        const { csvData } = response;
        yield put({ type: adminPayTypes.GET_PENDING_TRANSFER_SUCCESS, payload: { csvData } });
      } else {
        // TODO: revisar bien esto*
        yield put({
          type: adminPayTypes.GET_PENDING_TRANSFER_FAILURE,
          payload: { errorMessage: response.errorMessage },
        });
      }
    },
  );
}

function* sendTransferEmailsToPayToSellers() {
  yield takeLatest(adminPayTypes.PENDING_PAYMENT_EMAIL, function* (action) {
    const { transferIds } = action.payload;

    const body = JSON.stringify({ transferIds });

    yield apiRequest('api/v1/transfers/pending/payments-email', { method: 'POST', body }, true);
  });
}

export default function* adminPaySaga() {
  yield spawn(getPendingTransfer);
  yield spawn(uploadExcel);
  yield spawn(sendPaymentEmailsToPerform);
  yield spawn(sendTransferEmailsToPayToSellers);
}
