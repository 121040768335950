import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import { Typography, Box } from '@material-ui/core';

import { formatNumberToCLP } from '../../../utils/functions';
import useTotalAmountStyles from './useTotalAmountStyles';

const TotalAmountBox = (props) => {
  const {
    className, label, totalAmount, styles,
  } = props;

  const classes = useTotalAmountStyles();

  return (
    <Box style={{ ...styles }} className={`${classes.amountBoxContainer} ${className}`}>
      <Box className="amountTitleContainer">
        <Typography className="amountTitle">{label}</Typography>
      </Box>
      <Box className="amountBox">
        <Typography className="amount">{formatNumberToCLP(totalAmount)}</Typography>
      </Box>
    </Box>
  );
};

TotalAmountBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  totalAmount: PropTypes.number,
  enableBikfyPay: PropTypes.bool,
  styles: PropTypes.object,
};

TotalAmountBox.defaultProps = {
  className: '',
  label: 'Monto que recibirás',
  totalAmount: 0,
  enableBikfyPay: false,
  styles: {},
};

export default TotalAmountBox;
