/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { CREDIT_CARD_PAYMENT, CREDIT_CARD_PAYMENT_WITH_FEES, EXTERNAL_PAYMENT } from 'utils/consts';
import BikeDetailImage from 'commons/components/BikeDetailImage';
import colors from 'utils/colors';
import logoMercadoPago from 'commons/assets/paymentMethods/logo_mercado_pago.png';
import logoTransBank from 'commons/assets/paymentMethods/logo_transbank.png';
// import logoKipu from 'commons/assets/paymentMethods/logo_kipu.png';
import ModalPhotos from 'commons/components/ModalPhotos/ModalPhotos';
import TotalAmountBox from 'commons/components/TotalAmounBox/TotalAmounBox';
import PaymentMethodBox from './components/PaymentMethodBox';
import WarningModal from './components/WarningModal';

import styles from './styles';

const { green } = colors;

const useStyles = makeStyles(styles);

const paymentMethods = [
  {
    id: 1,
    title: 'Tarjeta de crédito ',
    subtitle: 'Entre 3 a 9 cuotas sin interés',
    logo: logoMercadoPago,
    key: CREDIT_CARD_PAYMENT_WITH_FEES,
    disabled: false,
  },
  {
    id: 2,
    title: 'Tarjetas de crédito y débito',
    subtitle: 'Pago al contado',
    logo: logoTransBank,
    key: CREDIT_CARD_PAYMENT,
    disabled: false,
  },
  // {
  //   id: 3,
  //   title: 'Transferencia bancaria',
  //   subtitle: '',
  //   logo: logoKipu,
  //   key: 'bank_transfer',
  //   disabled: true,
  // },
];

const paymentMethodskeyAdapter = (key) => {
  const options = {
    credit_card_with_fees: 'creditCardWithFees',
    credit_card: 'creditCard',
    bank_transfer: 'bankTransfer',
  };

  return options[key] || '';
};

const BikfyPay = (props) => {
  const {
    storeSeller,
    buyer,
    transfer,
    commission,
    confirmExternalPaymentToStore,
    createPayment,
    createMercadoPagoPayment,
    setPaymentMethod,
    paymentMethod,
    loading,
    history,
    bikeInfo,
  } = props;

  // const [paymentMethod, setSelectedPaymentMethod] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [openModalPhotos, setOpenModalPhotos] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const classes = useStyles();

  const { generalContainer, paymentTypeTitleContainer, paymentTypeContentContainer } = classes;

  const { transferDetail } = transfer;
  const { bikfyPayment } = transferDetail;

  const { bike } = transfer;

  useEffect(() => {
    if (!bikfyPayment) setPaymentMethod('external_payment');
  }, []);

  const handleOpenPhotos = (documents) => {
    setOpenModalPhotos(true);

    const docs = documents?.filter((doc) =>
      ['left_side', 'front_side', 'right_side'].includes(doc.type),
    );
    setPhotos(docs);
  };

  const handleClosePhotos = () => setOpenModalPhotos(false);

  const handleOpenWarningModal = () => setOpenWarningModal(true);

  const handleCloseWarningModal = () => setOpenWarningModal(false);

  const handleOnChange = (event) => {
    event.stopPropagation();
    const { value } = event.target;

    setPaymentMethod(value);
  };

  const handlePaymentMethodClick = (paymentMethodKey) => {
    setPaymentMethod(paymentMethodKey);
  };

  const handleClickContinueButton = (event) => {
    event.preventDefault();

    if (paymentMethod === CREDIT_CARD_PAYMENT || paymentMethod === EXTERNAL_PAYMENT) {
      if (transfer?.soldByStoreId && paymentMethod === EXTERNAL_PAYMENT) {
        confirmExternalPaymentToStore({
          isBikfyPayment: paymentMethod !== EXTERNAL_PAYMENT,
          transferId: transfer?.id,
          paymentMethod,
        });
      } else {
        createPayment({
          isBikfyPayment: paymentMethod !== EXTERNAL_PAYMENT,
          transferId: transfer?.id,
          paymentMethod,
        });
      }
    }

    if (paymentMethod === CREDIT_CARD_PAYMENT_WITH_FEES) {
      createMercadoPagoPayment({
        transferId: transfer?.id,
        paymentMethod,
      });
    }
  };

  const handleClickGoBackButton = (event) => {
    event.preventDefault();
    history.push({ pathname: '/', state: { bikeFilter: 3 } });
  };

  return (
    <Grid container component={Paper} justifyContent="center" className={generalContainer}>
      <Grid item xs={12} sm={10} lg={10}>
        {paymentMethod !== EXTERNAL_PAYMENT && (
          <Box className={paymentTypeTitleContainer}>
            <Typography className="general-title" variant="h5">
              ¿Cómo deseas
              <span className="bikfy-color"> pagar tu Bike?</span>
            </Typography>
          </Box>
        )}
        <Grid className={paymentTypeContentContainer} container justifyContent="center">
          <Grid item xs={11} md={12}>
            <PaymentMethodBox
              paymentMethods={paymentMethods.filter(
                ({ key }) => transferDetail[paymentMethodskeyAdapter(key)],
              )}
              selectedPaymentMethod={paymentMethod}
              handleChange={handleOnChange}
              handleClick={handlePaymentMethodClick}
            />
          </Grid>
          <Grid item xs={11} md={12}>
            <Paper style={{ padding: '20px', margin: '30px 0px' }}>
              <Typography className="paper-title">Resumen</Typography>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={4}>
                  <Carousel interval={10000}>
                    {(transfer?.bike?.document || []).map((doc) => (
                      <Box key={doc.id} style={{ height: '30vh' }}>
                        <BikeDetailImage
                          url={doc?.file?.url}
                          openModal={() => handleOpenPhotos(transfer?.bike?.document)}
                        />
                      </Box>
                    ))}
                  </Carousel>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="h5" variant="h5">
                    {bike.brand.name} {bike.model.name}
                  </Typography>
                  {bikeInfo.isBicycle && (
                    <>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Talla</b> {bike?.size?.name} | <b>Año</b> {bike.year} | <b>Aro</b>{' '}
                        {bike?.rim?.name}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Serial:</b> {bike?.serial}
                      </Typography>
                    </>
                  )}
                  {!bikeInfo.isBicycle && (
                    <>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Año</b> {bike.year} | <b>Cilindraje</b>{' '}
                        {bikeInfo?.motorbike?.engineCapacity?.capacity}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Número de motor:</b> {bikeInfo?.motorbike?.engineSerial}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Número de chasis:</b> {bikeInfo?.motorbike?.chassisSerial}
                      </Typography>
                    </>
                  )}
                  <Typography variant="subtitle1" color="textSecondary">
                    <b>Vendedor:</b>{' '}
                    {storeSeller ? storeSeller?.legalRepresentative?.name : buyer.name}{' '}
                    {storeSeller
                      ? storeSeller.legalRepresentative?.firstLastName
                      : buyer.firstLastName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <b>Firmado</b>
                    <CheckBoxIcon style={{ color: green, marginLeft: '.3rem' }} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {(!transfer?.soldByStoreId || paymentMethod !== EXTERNAL_PAYMENT) && (
                    <TotalAmountBox
                      label="Monto a Pagar"
                      totalAmount={
                        paymentMethod === EXTERNAL_PAYMENT
                          ? commission.bankTransfer
                          : Number(transfer.amount)
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box className="buttons-container">
            <Button
              variant="contained"
              color="secondary"
              className="button"
              onClick={handleClickGoBackButton}>
              Volver
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="button"
              id="btn-accept-transfer-buyer"
              onClick={
                paymentMethod === EXTERNAL_PAYMENT
                  ? handleClickContinueButton
                  : handleOpenWarningModal
              }
              disabled={!paymentMethod}>
              Continuar
              {loading && (
                <CircularProgress
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '5px',
                    color: 'white',
                  }}
                />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <ModalPhotos open={openModalPhotos} handleClose={handleClosePhotos} photos={photos} />

      <WarningModal
        title="¡AVISO!"
        text="En caso que tu transacción no pueda realizarse, ten presente que para pagos sobre
        $1.000.000, puede ser necesario habilitar o dar aviso a tu banco por temas de
        seguridad."
        loading={loading}
        openModal={openWarningModal}
        handleAccept={handleClickContinueButton}
        handleCancel={handleCloseWarningModal}
      />
    </Grid>
  );
};

BikfyPay.propTypes = {
  transfer: PropTypes.shape({}).isRequired,
  commission: PropTypes.shape({}).isRequired,
  createPayment: PropTypes.func.isRequired,
  createMercadoPagoPayment: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
};

BikfyPay.defaultProps = {
  loading: false,
};

export default BikfyPay;
