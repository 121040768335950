import { put, spawn, takeLatest } from 'redux-saga/effects';
import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { BENEFIT } from 'screens/Benefit/reducer';

function* fetchBikes() {
  yield takeLatest(BENEFIT.FETCH_BIKES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.FETCH_BIKES));
    const response = yield get('api/v1/bikes?bikeState=valid-bikes');
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.FETCH_BIKES));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(
        apiSuccess(
          BENEFIT.FETCH_BIKES_SUCCESS,
          Object.keys(response.objects.bikes ?? {}).length > 0,
        ),
      );
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las bikes' }));
      yield put(apiSuccess(BENEFIT.FETCH_BIKES_ERROR, { errorMsg: 'Error al obtener Bikes validas' }));
    }
  });
}

function* fetchBenefits() {
  yield takeLatest(BENEFIT.FETCH_BENEFITS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.FETCH_BENEFITS));
    const response = yield get('api/v1/benefits');
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.FETCH_BENEFITS));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BENEFIT.FETCH_BENEFITS_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los beneficios' }));
      yield put(apiSuccess(BENEFIT.FETCH_BENEFITS_ERROR, { errorMsg: 'Error al cargar los beneficios' }));
    }
  });
}
function* fetchProviders() {
  yield takeLatest(BENEFIT.FETCH_PROVIDERS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.FETCH_PROVIDERS));
    const response = yield get('api/v1/providers');
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.FETCH_PROVIDERS));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BENEFIT.FETCH_PROVIDERS_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los proveedores' }));
      yield put(apiSuccess(BENEFIT.FETCH_PROVIDERS_ERROR, { errorMsg: 'Error al cargar los proveedores' }));
    }
  });
}
function* fetchSubscriptions() {
  yield takeLatest(BENEFIT.FETCH_SUBSCRIPTIONS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.FETCH_SUBSCRIPTIONS));
    const response = yield get('api/v1/subscriptions');
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.FETCH_SUBSCRIPTIONS));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BENEFIT.FETCH_SUBSCRIPTIONS_SUCCESS));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los subscripciones' }));
      yield put(apiSuccess(BENEFIT.FETCH_SUBSCRIPTIONS_ERROR, { errorMsg: 'Error al cargar los subscripciones' }));
    }
  });
}
function* createSubscription() {
  yield takeLatest(BENEFIT.CREATE_SUBSCRIPTION, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.CREATE_SUBSCRIPTION));
    const response = yield post(
      'api/v1/subscriptions/create',
      { userId: action.payload.userId, providerId: action.payload.providerId },
    );
    console.log('mi response fue', response);
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.CREATE_SUBSCRIPTION));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BENEFIT.CREATE_SUBSCRIPTION_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al crear suscripción' }));
      yield put(apiSuccess(BENEFIT.CREATE_SUBSCRIPTION_ERROR, { errorMsg: 'Error al crear suscripción' }));
    }
  });
}

function* confirmSubscription() {
  yield takeLatest(BENEFIT.CONFIRM_SUBSCRIPTION, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BENEFIT.CONFIRM_SUBSCRIPTION));
    const response = yield post(
      'api/v1/subscriptions/confirm',
      { token: action.payload.token, subscriptionId: action.payload.subscriptionId },
    );
    console.log('mi response fue', response);
    yield put(apiSuccess(APP.REMOVE_LOADING, BENEFIT.CONFIRM_SUBSCRIPTION));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BENEFIT.CONFIRM_SUBSCRIPTION_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Tarjeta se ha agregado exitosamente'));
      yield put({ type: BENEFIT.FETCH_SUBSCRIPTIONS });
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al crear suscripción' }));
      yield put(apiSuccess(BENEFIT.CONFIRM_SUBSCRIPTION_ERROR, { errorMsg: 'Error al crear suscripción' }));
    }
  });
}

export default function* saga() {
  yield spawn(fetchBikes);
  yield spawn(fetchBenefits);
  yield spawn(fetchProviders);
  yield spawn(createSubscription);
  yield spawn(confirmSubscription);
  yield spawn(fetchSubscriptions);
}
