import React from 'react';
import {
  Typography, Grid, Avatar, makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import BIKFYPAY from 'commons/assets/landing_items/new_bikfypay_image.png';
import CLUB from 'commons/assets/landing_items/club_bikfy_image.png';
import INSURANCE from 'commons/assets/landing_items/insurance_image.png';
import MARKETPLACE from 'commons/assets/landing_items/marketplace_image.png';
import REGISTER from 'commons/assets/landing_items/register_image.png';
import REPORT from 'commons/assets/landing_items/report_image.png';
import TRANSFER from 'commons/assets/landing_items/new_transfer_image.png';
import { formatNumber } from 'utils/functions';
import styles from './styles';

const infoUrl = process.env.REACT_APP_INFO_URL;

const useStyles = makeStyles(styles);

const LandingItems = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const handleAvatarClick = (route) => {
    window.open(route, '_blank');
  };
  const {
    isMobile, usersCount, isXsScreen, user,
  } = props;
  const itemsList = [
    {
      title: 'Registrar Bicicleta/Moto',
      description: 'Gratis',
      image: REGISTER,
      route: user ? '/register-bike' : `${infoUrl}/bikfy-servicios/#registro`,
    },
    {
      title: 'Vender Bicicleta/Moto',
      description: 'Gratis',
      image: MARKETPLACE,
      route: user ? '/home' : `${infoUrl}/bikfy-servicios/#compravende`,
    },
    {
      title: 'Transferencia legal',
      description: 'Desde $11.990',
      image: TRANSFER,
      route: user ? '/home' : `${infoUrl}/bikfy-servicios/#transfer`,
    },
    {
      title: 'Seguro de Bicicleta',
      description: 'Desde $5.500',
      image: INSURANCE,
      route: user ? '/home?filter=4' : `${infoUrl}/bikfy-servicios/#seguros`,
    },
    {
      title: 'BIKFYPAY',
      description: 'Comisión desde 4%',
      image: BIKFYPAY,
      route: user ? '/home' : `${infoUrl}/bikfy-servicios/#bikfypay`,
    },
    {
      title: 'Denuncia un robo',
      description: 'Gratis',
      image: REPORT,
      route: user ? '/home' : `${infoUrl}/bikfy-servicios/#denuncia`,
    },
    {
      title: 'Club BIKFY',
      description: 'Gratis',
      image: CLUB,
      route: user ? 'https://www.bikfy.com/club-bikfy' : `${infoUrl}/bikfy-servicios/club-bikfy/`,
    },
  ];
  return (
    <>
      {isMobile ? (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          {/* <Grid item xs={12}>
            <Typography
              component="h1"
              align="center"
              style={{
                fontWeight: 'bold',
                fontSize: '36px',
                color: '#303E48',
                paddingBottom: '18px',
                lineHeight: '40px',
              }}>
              Registro y venta de bicicletas usadas
            </Typography>
            <Typography
              component="h2"
              align="center"
              style={{ fontSize: '20x', margin: '0 5px', lineHeight: '1' }}>
              Somos el primer Registro Nacional de Bicicletas con Servicios integrados
              <br />
            </Typography>
            <Typography
              component="h2"
              align="center"
              style={{ fontSize: '20px', margin: '0 5px', paddingTop: '20px' }}>
              <span style={{ fontWeight: '800', color: '#45c6d8' }}>
                Únete a los {formatNumber(usersCount)} ciclistas ya registrados
              </span>
            </Typography>
          </Grid> */}
          {/* <br /> */}
          <Grid item xs={12}>
            <Typography
              align="center"
              component="h3"
              style={{
                fontWeight: '500',
                fontSize: '30px',
                paddingBottom: '12px',
                color: '#303E48',
              }}
            >
              Servicios BIKFY
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: '262px',
              width: isXsScreen ? '23rem' : '100%',
              overflowX: isXsScreen ? 'scroll' : 'hidden',
              overflowY: 'clip',
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="baseline"
              justifyContent="flex-start"
              spacing={2}
              wrap={isXsScreen ? 'nowrap' : 'wrap'}
            >
              >
              {itemsList.map((item) => (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Avatar
                        alt="Cindy Baker"
                        src={item.image}
                        className={classes.avatar}
                        onClick={() => (item.route.includes('http')
                          ? handleAvatarClick(item.route)
                          : history.push(item.route))}
                        style={{ width: 90, height: 90 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        noWrap={false}
                        align="center"
                        style={{
                          fontWeight: '500',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                          lineHeight: '1.2',
                        }}
                        component="h4"
                      >
                        {item.title}
                      </Typography>
                      <Typography align="center" style={{ fontWeight: '200', fontSize: '11px' }}>
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          {/* <Grid item xs={12}>
            <Typography
              component="h1"
              align="center"
              style={{ fontWeight: 'bold', fontSize: '48px', color: '#303E48' }}>
              Registro y venta de bicicletas usadas
            </Typography>
            <Typography
              component="h2"
              align="center"
              style={{ fontSize: '22px', color: '#303E48' }}>
              Somos el primer Registro Nacional de Bicicletas con Servicios integrados
              <br />
              <span style={{ color: '#45c6d8', fontWeight: '800' }}>
                ¡Únete a los {formatNumber(usersCount)} ciclistas ya registrados!
              </span>
            </Typography>
          </Grid> */}
          {/* <br /> */}
          <Grid item xs={12}>
            <Typography
              align="center"
              component="h3"
              style={{
                fontWeight: '500',
                fontSize: '30px',
                paddingTop: '10px',
                paddingBottom: '20px',
                color: '#303E48',
              }}
            >
              Servicios BIKFY
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="baseline"
              justifyContent="space-around"
              spacing={2}
              style={{ paddingBottom: '20px' }}
            >
              {itemsList.map((item) => (
                <Grid item xs>
                  <Grid
                    container
                    wrap="nowrap"
                    direction="column"
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={2}
                    onClick={() => (item.route.includes('http')
                      ? handleAvatarClick(item.route)
                      : history.push(item.route))}
                    style={{ cursor: 'pointer' }}
                    className={classes.avatar}
                  >
                    <Grid item>
                      <Avatar
                        alt={item.title}
                        src={item.image}
                        style={{ width: 130, height: 130 }}
                        variant="rounded"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        noWrap={false}
                        align="center"
                        style={{ fontWeight: '500', fontSize: '15px', textTransform: 'uppercase' }}
                        component="h4"
                      >
                        {item.title}
                      </Typography>
                      <Typography align="center" style={{ fontWeight: '200', fontSize: '13px' }}>
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LandingItems;
