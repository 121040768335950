import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Snackbar,
  Step,
  Stepper,
  StepLabel,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { useAuth } from 'commons/context/user';
import {
  FIRST_STAGE_TRANSFER_ID,
  SECOND_STAGE_TRANSFER_ID,
  THIRD_STAGE_TRANSFER_ID,
  FOURTH_STAGE_TRANSFER_ID,
  TOTAL_SELLER_FLOW_STEPS,
} from 'utils/consts';
import TransferSummary from 'screens/Transfer/components/transferPreview/components/transferSummary';
import Contract from 'screens/TransferPreview/components/Contract';
import TransferButton from 'screens/Transfer/components/transferPreview/components/transferButton';
import saga from 'screens/TransferPreview/saga';
import { transferPreviewActions } from 'screens/TransferPreview/reducer';
import DocumentModal from 'screens/TransferPreview/components/DocumentModal';
import SimpleStepper from 'commons/components/SimpleStepper';
import { PATHS } from 'utils/paths';
import VerificationCode from './components/VerificationCode';
import BikfyPay from './components/BikfyPay/BikfyPay';
import SuccessPay from './components/SuccessPay/SuccessPay';

import styles from './styles';

const useStyles = makeStyles(styles);

const CREDIT_CARD_PAYMENT = 'credit_card';
const CREDIT_CARD_PAYMENT_WITH_FEES = 'credit_card_with_fees';
const EXTERNAL_PAYMENT = 'external_payment';

const TransferPreview = (props) => {
  const { actions, loading, transferPreview: transferPreviewState, objects } = props;
  const { code, error, message, paymentMethod, successNewConfirmationCode, transbank } =
    transferPreviewState;
  const {
    clearError,
    createTransferPDF,
    getTransfer,
    confirmExternalPaymentToStore,
    createPayment,
    createMercadoPagoPayment,
    fetchTransferPreview,
    setPaymentMethod,
    generateNewVerificationCode,
    validateVerificationCode,
  } = actions;

  const { id } = props.match.params;

  const user = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { id: transferId } = useParams();
  const classes = useStyles();

  const { stepper } = classes;

  // ?result=eyJicmFuZCI6IiBaZW5pdGgiLCJjYXRlZ29yeSI6IkNyb3NzQ291bnRyeSIsInNlcmlhbCI6IjEyIn0=
  const transfer = objects?.transfers?.[transferId];
  const bikeDetails = transfer?.bike;
  const step = transfer?.transferDetail?.step;
  const commission = Object.values(objects?.commissions)[0];

  useEffect(() => {
    getTransfer({ transferId });
  }, []);

  useEffect(() => {
    fetchTransferPreview(id);
  }, [location, id]);

  useEffect(() => {
    if (transbank.url !== '' && transbank.token !== '') {
      const urlOptions = {
        [EXTERNAL_PAYMENT]: transbank.url.concat(`?token_ws=${transbank.token}`),
        [CREDIT_CARD_PAYMENT]: transbank.url.concat(`?token_ws=${transbank.token}`),
        [CREDIT_CARD_PAYMENT_WITH_FEES]: transbank.url,
      };

      window.location.replace(urlOptions[paymentMethod]);
    }
  }, [transbank]);

  useEffect(() => {
    props.actions.resetControls();
  }, [props.actions.resetControls]);

  useEffect(() => {
    if (props.successSeller) {
      props.actions.resetControls();
      const url = props?.formControls?.transferPreview?.soldByStoreId
        ? PATHS.STORE
        : `${PATHS.PILOT}?filter=3`;
      history.push(url);
    }
  }, [props.successSeller]);

  useEffect(() => {
    if (props.successBuyer) {
      props.actions.resetControls();
      history.push('/');
    }
  }, [props.successBuyer]);

  useEffect(() => {
    if (props.successDeleteTransfer) {
      props.actions.resetControls();
      history.push('/');
    }
  }, [props.successDeleteTransfer]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const { transferPreview } = props.formControls;

  let amount = 0;
  let buyer = [];
  let seller = [];
  let bike = [];
  let bikeId = '';
  let storeSellerId = [];
  let storeSeller = [];
  if (transferPreview) {
    amount = transferPreview.amount;
    buyer = transferPreview.buyer;
    seller = transferPreview.seller;
    bike = transferPreview.bike;
    bikeId = bike.transfers[0]?.id;
    storeSellerId = transferPreview.soldByStoreId;
    storeSeller = transferPreview.storeSeller;
  }

  const userType =
    user.id === buyer.id
      ? 'buyer'
      : user.id === seller.id || user.id === storeSellerId
      ? 'seller'
      : null;

  const handleOpenModal = (e) => {
    e.preventDefault();
    props.actions.openModal();
  };

  // TODO: create component
  const lastScreen = (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center" style={{ marginTop: '2rem' }}>
                <Typography
                  variant="h6"
                  component="span"
                  color="textSecondary"
                  style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                  Revisa el
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                    style={{ fontWeight: '700', textTransform: 'uppercase' }}>
                    {' '}
                    Contrato de Compraventa{' '}
                  </Typography>
                  para una transferencia exitosa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Card variant="outlined" style={{ border: '1px solid black' }}>
                  <CardContent>{transferPreview && <Contract id={id} />}</CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TransferSummary
                  handleOpenModal={handleOpenModal}
                  amount={amount}
                  buyer={buyer}
                  seller={seller}
                  bike={bike}
                  storeSeller={storeSeller}
                />
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <TransferButton
                  user={userType}
                  id={id}
                  acceptSeller={props.actions.acceptTransferSeller}
                  loading={props.loading}
                  alertDeny={props.alertDeny}
                  openAlertDeny={props.actions.openAlert}
                  clearAlertDeny={props.actions.clearAlert}
                  deleteTransfer={props.actions.deleteTransfer}
                  createTransferPDF={createTransferPDF}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <DocumentModal
        open={props.documentModal}
        clearModal={props.actions.clearModal}
        documents={bike?.documents}
      />
    </Grid>
  );

  const sellerName = `${seller?.name} ${seller?.firstLastName}`;

  return (
    <Grid style={{ width: '100%' }}>
      {(userType === 'buyer' || location.search) && (
        <Stepper
          activeStep={step === FOURTH_STAGE_TRANSFER_ID ? step + 1 : step - 1}
          className={stepper}>
          <Step>
            <StepLabel />
          </Step>
          <Step>
            <StepLabel />
          </Step>
          <Step>
            <StepLabel />
          </Step>
          <Step>
            <StepLabel />
          </Step>
        </Stepper>
      )}

      {userType === 'seller' && (
        <Grid container>
          <SimpleStepper activeStep={TOTAL_SELLER_FLOW_STEPS} stepTotal={TOTAL_SELLER_FLOW_STEPS} />
        </Grid>
      )}

      <Grid>
        {step === FIRST_STAGE_TRANSFER_ID && commission ? (
          lastScreen
        ) : (
          <Box display="flex" justifyContent="center">
            {!commission && <CircularProgress />}
          </Box>
        )}
        {step === SECOND_STAGE_TRANSFER_ID && (
          <BikfyPay
            buyer={buyer}
            transfer={transfer}
            commission={commission}
            createPayment={createPayment}
            createMercadoPagoPayment={createMercadoPagoPayment}
            setPaymentMethod={setPaymentMethod}
            paymentMethod={paymentMethod}
            loading={loading}
            history={history}
            bikeInfo={bike}
            storeSeller={storeSeller}
            confirmExternalPaymentToStore={confirmExternalPaymentToStore}
          />
        )}
        {step === THIRD_STAGE_TRANSFER_ID && (
          <VerificationCode
            code={code}
            generateNewVerificationCode={generateNewVerificationCode}
            loading={loading}
            transferId={transfer.id}
            sellerName={sellerName}
            validateVerificationCode={validateVerificationCode}
            history={history}
          />
        )}
        {step === FOURTH_STAGE_TRANSFER_ID && (
          <SuccessPay
            bikeDetails={bikeDetails}
            history={history}
            isBikfyPay={!transfer?.transferDetail.externalPayment}
          />
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={clearError}>
        <Alert onClose={clearError} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successNewConfirmationCode}
        autoHideDuration={3000}
        onClose={clearError}>
        <Alert onClose={clearError} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default composedComponent(TransferPreview, saga, {
  states: [
    'transferPreview.loading',
    'transferPreview.error',
    'transferPreview.successSeller',
    'transferPreview.successBuyer',
    'transferPreview.formControls',
    'transferPreview.flow',
    'transferPreview.alertDeny',
    'transferPreview.successDeleteTransfer',
    'transferPreview.documentModal',
    'transferPreview.fileId',
    'transferPreview.loaders',
    'transferPreview.paymentMethod',
    'transferPreview.code',
    'transferPreview',
    'app.objects',
  ],
  actions: [transferPreviewActions, appActions],
  saga: 'transferPreviewSaga',
});
