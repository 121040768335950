import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import RegisterForm from 'screens/Bike/components/registerFrom';
import EditForm from 'screens/Bike/components/editForm';
import {
  blobToFile,
  composedComponent,
  compressImage,
  formatTaxNumber,
  getParameterByName,
  imageToBlobAsync,
  serialInput,
  validateRut,
} from 'utils/functions';
import saga from 'screens/Bike/saga';
import { appActions } from 'commons/reducer';
import { bikeActions } from 'screens/Bike/reducer';
import { useForm } from 'utils/hooks/useForm';
import CustomModal from 'commons/components/CustomModalConfirmation';
import ModalSerial from 'screens/Bike/components/modalSerial';
import years from 'utils/years';
import { PATHS } from 'utils/paths';
import { useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { specialCharFormatForBikes, serialSpecialCharFormat } from 'utils/consts';

const Bike = (props) => {
  const [isBicycleSelected, setIsBicycleSelected] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const isStoreUser = props?.user?.user?.type === 'store';
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const vehicleTypeSelected = Object.values(props?.vehicleTypes)
      .filter((vehicleType) => vehicleType.id === props?.formControls?.vehicleTypeId)
      .pop();
    if (vehicleTypeSelected?.name && vehicleTypeSelected?.name !== 'Bicicleta') {
      setIsBicycleSelected(false);
    } else {
      setIsBicycleSelected(true);
    }
  }, [props.formControls.vehicleTypeId, props?.vehicleTypes]);

  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchModels();
    props.actions.fetchCategories();
    props.actions.fetchSizes();
    props.actions.fetchRims();
    props.actions.fetchCurrencies();
    props.actions.fetchVehicleTypes();
    props.actions.fetchMotorbikeCategories();
    props.actions.fetchMotorbikeBrands();
    props.actions.fetchMotorbikeModels();
    props.actions.fetchEngineCapacities();
  }, [
    props.actions.fetchBrands,
    props.actions.fetchModels,
    props.actions.fetchCategories,
    props.actions.fetchSizes,
    props.actions.fetchRims,
    props.actions.fetchCurrencies,
    props.actions.fetchVehicleTypes,
    props.actions.fetchMotorbikeCategories,
    props.actions.fetchMotorbikeBrands,
    props.actions.fetchMotorbikeModels,
    props.actions.fetchEngineCapacities,
  ]);

  useEffect(() => {
    if (props.successRegister) {
      history.push(PATHS.PILOT);
    }
  }, [props.successRegister]);

  useEffect(() => {
    if (props.successUpdateReport) {
      history.push(PATHS.PILOT);
    }
  }, [props.successUpdateReport]);

  useEffect(() => {
    if (props.successEdit) {
      const ref = getParameterByName('ref', location.search);

      if (ref === 'home') {
        history.push(PATHS.PILOT);
      } else if (history.length > 2) {
        history.goBack();
      } else {
        history.push(PATHS.PILOT);
      }
    }
  }, [props.successEdit]);

  useEffect(() => props.actions.resetControls, []);

  const handleRemoveReport = (e) => {
    e.preventDefault();
    props.actions.updateReport(props.match.params.id);
  };

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({
      ...(name === 'brandId' ? { modelId: '' } : {}),
      ...(name === 'vehicleTypeId' ? { brandId: '', modelId: '', categoryId: '' } : {}),
      ...(name === 'vehicleTypeId' && value === ''
        ? {
          brandId: '',
          modelId: '',
          categoryId: '',
          invoiceCheckbox: false,
          invoiceInMyName: false,
          foreignCheckbox: false,
        }
        : {}),
      [name]: value,
    });
  }, []);

  const {
    onChange, onSubmit, errors, validateFields, validateAll, validateFieldsWithoutSettingErrors,
  } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      vehicleTypeId: [
        {
          check: (value) => !!value,
          message: 'El Tipo de Vehículo es requerido',
        },
      ],
      color: [
        {
          check: (value) => !specialCharFormatForBikes.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      version: [
        {
          check: (value) => !specialCharFormatForBikes.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      categoryId: [
        {
          check: (value) => !!value,
          message: 'La Categoría es requerida',
        },
      ],
      brandId: [
        {
          check: (value) => !!value,
          message: 'La Marca es requerida',
        },
      ],
      modelId: [
        {
          check: (value) => !!value,
          message: 'El Modelo es requerido',
        },
      ],
      sizeId: [
        {
          check: (value) => !!value || !isBicycleSelected,
          message: 'La Talla es requerida',
        },
      ],
      year: [
        {
          check: (value) => !!value,
          message: 'El Año es requerido',
        },
      ],
      rimId: [
        {
          check: (value) => !!value || !isBicycleSelected,
          message: 'El Aro es requerido',
        },
      ],
      currencyId: [
        {
          check: (value) => !props.formControls.invoiceCheckbox || !!value || !props.formControls.dataCompleted,
          message: 'Requerida',
        },
      ],
      serial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || !isBicycleSelected || !props.formControls.dataCompleted,
          message: 'El Código de serie es requerido',
        },
        {
          check: (value) => !isBicycleSelected || !serialSpecialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      chassisSerial: [
        {
          check: (value) => !!value || isBicycleSelected || !props.formControls.dataCompleted,
          message: 'El Código de serie es requerido',
        },
        {
          check: (value) => isBicycleSelected || !serialSpecialCharFormat.test(value) || !props.formControls.dataCompleted,
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      engineSerial: [
        {
          check: (value) => !!value || isBicycleSelected || !props.formControls.dataCompleted,
          message: 'El Código de serie es requerido',
        },
        {
          check: (value) => isBicycleSelected || !serialSpecialCharFormat.test(value) || !props.formControls.dataCompleted,
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      amount: [
        {
          check: (value) => !props.formControls.invoiceCheckbox || value || !props.formControls.dataCompleted,
          message: 'El Valor es requerido',
        },
      ],
      invoiceTaxNumber: [
        {
          check: (value) => !props.formControls.invoiceCheckbox
            || (!props.formControls.foreignCheckbox
              ? validateRut(value)
              : value.trim().length > 0 && value) || !props.formControls.dataCompleted,
          message: !props.formControls.foreignCheckbox
            ? 'El RUT es inválido'
            : 'El Nombre de la tienda es requerido',
        },
      ],
      companyTaxNumber: [
        {
          check: (value) => props.formControls.invoiceInMyName !== 3
            || (!props.formControls.companyTaxNumber
              ? validateRut(value)
              : value.trim().length > 0 && value) || !props.formControls.invoiceCheckbox,
          message: 'El RUT es inválido',
        },
      ],
      companyName: [
        {
          check: (value) => props.formControls.invoiceInMyName !== 3 || (value && value.trim().length > 0) || !props.formControls.invoiceCheckbox,
          message: 'El nombre es requerido',
        },
      ],
      companyAddress: [
        {
          check: (value) => props.formControls.invoiceInMyName !== 3 || (value && value.trim().length > 0) || !props.formControls.invoiceCheckbox,
          message: 'La dirección es requerida',
        },
      ],
      invoiceNumber: [
        {
          check: (value) => !props.formControls.invoiceCheckbox || (value && value.trim().length > 0) || !props.formControls.dataCompleted,
          message: 'El Número es requerido',
        },
      ],
      invoiceFile: [
        {
          check: (value) => !props.formControls.invoiceCheckbox || value || !props.formControls.dataCompleted,
          message: 'El Archivo es requerido',
        },
      ],
      serialFile: [
        {
          check: (value) => props.formControls.invoiceCheckbox || value || !props.formControls.dataCompleted,
          message: 'El Archivo es requerido',
        },
      ],
      possessionCheckbox: [
        {
          check: (value) => props.formControls.possessionCheckbox || value,
          message: 'Este campo es requerido',
        },
      ],
      purchasedAt: [
        {
          check: (value) => !props.formControls.invoiceCheckbox
            || value.length === 0
            || new Date(value) < new Date() || !props.formControls.dataCompleted,
          message: 'La máxima fecha de compra disponible es hoy',
        },
      ],
      // invoiceCompanyCheckbox: [
      //   {
      //     check: (value) => !props.formControls.invoiceCheckbox
      //       || value.length === 0
      //       || new Date(value) < new Date(),
      //     message: 'La máxima fecha de compra disponible es hoy',
      //   },
      // ],
    },
  });

  const transfer = props.objects?.transfers[props.match.params.id];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!props.formControls.invoiceCheckbox) {
      inputs.forEach((input) => {
        if (input.status === true) {
          changeControls(input.name, '');
        }
      });
      changeControls('invoiceFile', '');
    }
    const blobs = await Promise.all(props.formControls.croppedImages.map(imageToBlobAsync));
    const compressedFiles = await Promise.all(
      blobs.map((blob, index) => compressImage(blobToFile(blob, props.formControls.filesNames[index]))),
    );

    onSubmit(() => (transfer?.id
      ? props.actions.editBike(
        { ...props.formControls, transferId: transfer.id },
        compressedFiles,
      )
      : props.actions.registerBike(props.formControls, compressedFiles)));
  };

  const sizes = Object.values(props.sizes).map((s) => ({
    value: s?.id,
    label: s?.name,
  }));

  const rims = props.order?.rims
    ?.map((b) => props.rims[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const currencies = props.order?.currencies
    ?.map((b) => props.currencies[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const brands = props.order?.brands
    ?.map((b) => props.brands[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const models = props.order?.models
    ?.map((m) => props.models[m])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      brandId: a?.brandId,
    }));

  const categories = props.order?.categories
    ?.map((c) => props.categories[c])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const vehicleTypes = props.order?.vehicleTypes
    ?.map((vt) => props.vehicleTypes[vt])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const motorbikeCategories = props.order?.motorbikeCategories
    ?.map((mc) => props.motorbikeCategories[mc])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const motorbikeBrands = props.order?.motorbikeBrands
    ?.map((mb) => props.motorbikeBrands[mb])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const motorbikeModels = props.order?.motorbikeModels
    ?.map((mm) => props.motorbikeModels[mm])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      brandId: a?.brandId,
    }));

  const engineCapacities = props.order?.engineCapacities
    ?.map((ec) => props.engineCapacities[ec])
    .map((a) => ({
      value: a?.id,
      label: a?.capacity,
    }));

  const filteredModels = useMemo(
    () => models.filter((m) => m.brandId === props.formControls.brandId),
    [props.formControls.brandId, models],
  );

  const filteredMotorbikeModels = useMemo(
    () => motorbikeModels.filter((m) => m.brandId === props.formControls.brandId),
    [props.formControls.brandId, models],
  );

  const handlebrandOptions = () => {
    if (!props.formControls.vehicleTypeId) {
      return [];
    }
    if (isBicycleSelected) {
      return brands;
    }
    return motorbikeBrands;
  };

  const handleCategoryOptions = () => {
    if (!props.formControls.vehicleTypeId) {
      return [];
    }
    if (isBicycleSelected) {
      return categories;
    }
    return motorbikeCategories;
  };

  const handleModelOptions = () => {
    if (!props.formControls.vehicleTypeId) {
      return [];
    }
    if (isBicycleSelected) {
      return filteredModels;
    }
    return filteredMotorbikeModels;
  };

  const inputs = [
    {
      name: 'vehicleTypeId',
      label: 'Tipo de vehículo',
      type: 'vehicleTypeSelect',
      md: 12,
      sm: 12,
      options: vehicleTypes,
      visible: true,
    },
    {
      name: 'categoryId',
      label: 'Categoría',
      type: 'select',
      options: handleCategoryOptions(),
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'brandId',
      label: 'Marca',
      type: 'select',
      options: handlebrandOptions(),
      info: true,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'modelId',
      label: 'Modelo',
      type: 'select',
      options: handleModelOptions(),
      info: true,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'version',
      label: 'Versión',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'sizeId',
      label: 'Talla',
      visible: isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      type: 'select',
      options: sizes,
    },
    {
      name: 'year',
      label: 'Año',
      type: 'select',
      options: years(),
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'rimId',
      label: 'Aro',
      visible: isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      type: 'select',
      options: rims,
    },
    {
      name: 'color',
      label: 'Color',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'engineCapacityId',
      label: 'Cilindraje Motor',
      visible: !isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      type: 'select',
      options: engineCapacities,
    },
    {
      name: 'serial',
      label: 'Código de serie',
      type: 'adornment',
      md: props.formControls.invoiceCheckbox ? 6 : 12,
      visible: isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      last: !props.formControls.invoiceCheckbox,
      getValue: (s = '') => serialInput(s),
    },
    {
      name: 'chassisSerial',
      label: 'Número de chasis',
      type: 'adornment',
      lg: 6,
      visible: !isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      last: true,
      getValue: (s = '') => serialInput(s),
    },
    {
      name: 'engineSerial',
      label: 'Número de serie motor',
      type: 'adornment',
      lg: 6,
      visible: !isBicycleSelected && Boolean(props?.formControls?.vehicleTypeId),
      last: true,
      getValue: (s = '') => serialInput(s),
    },
    {
      // here
      name: 'currencyId',
      label: 'Moneda',
      type: 'select',
      options: currencies,
      status: !props.formControls.invoiceCheckbox,
      md: 3,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'amount',
      label: 'Valor en factura o boleta',
      type: 'number',
      status: !props.formControls.invoiceCheckbox,
      inputProp: true,
      md: 3,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'invoiceTaxNumber',
      label: !props.formControls.foreignCheckbox
        ? 'RUT emisor factura o boleta'
        : 'Nombre de tienda emisora',
      type: 'text',
      status: !props.formControls.invoiceCheckbox,
      getValue: formatTaxNumber,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'companyTaxNumber',
      label: 'RUT empresa',
      type: 'text',
      status: !props.formControls.invoiceCheckbox,
      getValue: formatTaxNumber,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'companyName',
      label: 'Razón social',
      type: 'text',
      status: !props.formControls.invoiceCheckbox,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'companyAddress',
      label: 'Dirección empresa',
      type: 'text',
      status: !props.formControls.invoiceCheckbox,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'invoiceNumber',
      label: 'Número factura o boleta',
      type: 'text',
      status: !props.formControls.invoiceCheckbox,
      disabled: isStoreUser,
      visible: true,
    },
    {
      name: 'purchasedAt',
      label: 'Fecha de compra',
      type: 'date',
      status: !props.formControls.invoiceCheckbox,
      md: 6,
      visible: true,
    },
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10}>
        <Switch>
          <Route
            exact
            path="/register-bike"
            render={() => (
              <RegisterForm
                isBicycleSelected={isBicycleSelected}
                onSubmit={handleSubmit}
                onControlChange={onChange}
                errors={errors}
                inputs={inputs}
                controls={props.formControls}
                validateFields={validateFields}
                validateAll={validateAll}
                validateFieldsWithoutSettingErrors={validateFieldsWithoutSettingErrors}
                actions={props.actions}
                loading={props.bikeLoading}
                openAlert={props.actions.openAlertSerial}
                profileComplete={props.user?.user.dataComplete}
                isMobileScreen={isMobileScreen}
              />
            )}
          />
          <Route
            exact
            path="/bike/:id"
            render={() => (
              <EditForm
                isBicycleSelected={isBicycleSelected}
                onSubmit={handleSubmit}
                openAlert={props.actions.openAlert}
                onControlChange={onChange}
                vehicleTypeFetched={props.vehicleTypeFetched}
                errors={errors}
                inputs={inputs}
                controls={props.formControls}
                actions={props.actions}
                imagesData={props.imagesData}
                loading={props.bikeLoading}
                transfer={transfer}
                objects={props.objects}
                validateAll={validateAll}
                transferId={props.match.params.id}
                changeControls={props.actions.changeControls}
                openAlertSerial={props.actions.openAlertSerial}
                profileComplete={props.user?.user.dataComplete}
                isStoreUser={isStoreUser}
                isMobileScreen={isMobileScreen}
              />
            )}
          />
        </Switch>
      </Grid>
      <ModalSerial
        clearAlert={props.actions.clearAlertSerial}
        open={props.alertSerial}
        isBicycleSelected={isBicycleSelected}
      />
      <CustomModal
        alert={props.alert}
        clearAlert={props.actions.clearAlert}
        handleSubmit={handleRemoveReport}
        loading={props.loading}
        text="Al eliminar la denuncia se activarán las funciones bloqueadas."
      />
    </Grid>
  );
};

export default composedComponent(Bike, saga, {
  states: [
    'bike.bikeLoading',
    'bike.vehicleTypeFetched',
    'bike.error',
    'bike.formControls',
    'bike.successRegister',
    'bike.successEdit',
    'bike.successUpdateReport',
    'bike.alert',
    'bike.alertSerial',
    'bike.imagesData',
    'app.objects.brands',
    'app.objects.models',
    'app.objects.categories',
    'app.objects.sizes',
    'app.objects.rims',
    'app.objects.engineCapacities',
    'app.objects.currencies',
    'app.objects.vehicleTypes',
    'app.objects.motorbikeCategories',
    'app.objects.motorbikeBrands',
    'app.objects.motorbikeModels',
    'app.objects',
    'app.order',
    'app.user',
  ],
  actions: [bikeActions, appActions],
  saga: 'bikeSaga',
});
