/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';
// Local constants
const PATH = 'admin/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const ADMIN = {
  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
  CLEAR_ALERT_DELETE: `${PATH}CLEAR_ALERT_DELETE`,
  OPEN_ALERT_DELETE: `${PATH}OPEN_ALERT_DELETE`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  TOGGLE_FILTER: `${PATH}TOGGLE_FILTER`,

  SET_PAGINATION: `${PATH}SET_PAGINATION`,
  RESET_PAGINATION: `${PATH}RESET_PAGINATION`,

  FETCH_BIKES: `${PATH}FETCH_BIKES`,
  FETCH_BIKES_SUCCESS: `${PATH}FETCH_BIKES_SUCCESS`,
  FETCH_BIKES_ERROR: `${PATH}FETCH_BIKES_ERROR`,

  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_ERROR`,

  FETCH_MODELS: `${PATH}FETCH_MODELS`,
  FETCH_MODELS_SUCCESS: `${PATH}FETCH_MODELS_SUCCESS`,
  FETCH_MODELS_ERROR: `${PATH}FETCH_MODELS_ERROR`,

  FETCH_CATEGORIES: `${PATH}FETCH_CATEGORIES`,
  FETCH_CATEGORIES_SUCCESS: `${PATH}FETCH_CATEGORIES_SUCCESS`,
  FETCH_CATEGORIES_ERROR: `${PATH}FETCH_CATEGORIES_ERROR`,

  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,
  FETCH_VEHICLE_TYPES_ERROR: `${PATH}FETCH_VEHICLE_TYPES_ERROR`,

  FETCH_ENGINE_CAPACITIES: `${PATH}FETCH_ENGINE_CAPACITIES`,
  FETCH_ENGINE_CAPACITIES_SUCCESS: `${PATH}FETCH_ENGINE_CAPACITIES_SUCCESS`,
  FETCH_ENGINE_CAPACITIES_ERROR: `${PATH}FETCH_ENGINE_CAPACITIES_ERROR`,

  FETCH_MOTORBIKE_MODELS: `${PATH}FETCH_MOTORBIKE_MODELS`,
  FETCH_MOTORBIKE_MODELS_SUCCESS: `${PATH}FETCH_MOTORBIKE_MODELS_SUCCESS`,
  FETCH_MOTORBIKE_MODELS_ERROR: `${PATH}FETCH_MOTORBIKE_MODELS_ERROR`,

  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS_ERROR: `${PATH}FETCH_MOTORBIKE_BRANDS_ERROR`,

  FETCH_MOTORBIKE_CATEGORIES: `${PATH}FETCH_MOTORBIKE_CATEGORIES`,
  FETCH_MOTORBIKE_CATEGORIES_SUCCESS: `${PATH}FETCH_MOTORBIKE_CATEGORIES_SUCCESS`,
  FETCH_MOTORBIKE_CATEGORIES_ERROR: `${PATH}FETCH_MOTORBIKE_CATEGORIES_ERROR`,

  TOGGLE_DISABLE_BIKE: `${PATH}TOGGLE_DISABLE_BIKE`,
  TOGGLE_DISABLE_BIKE_SUCCESS: `${PATH}TOGGLE_DISABLE_BIKE_SUCCESS`,
  TOGGLE_DISABLE_BIKE_ERROR: `${PATH}TOGGLE_DISABLE_BIKE_ERROR`,

  SHOW_DISABLE_DIALOG: `${PATH}SHOW_DISABLE_DIALOG`,

  SET_DISABLE_FORM_DIALOG: `${PATH}SET_DISABLE_FORM_DIALOG`,

  CHANGE_VEHICLE_TYPE_RESET_CONTROLS: `${PATH}CHANGE_VEHICLE_TYPE_RESET_CONTROLS`,
};

// actions
export const adminActions = {
  fetchBikes: createAction(ADMIN.FETCH_BIKES, 'payload'),
  fetchBrands: createAction(ADMIN.FETCH_BRANDS),
  fetchModels: createAction(ADMIN.FETCH_MODELS),
  fetchCategories: createAction(ADMIN.FETCH_CATEGORIES),
  fetchVehicleTypes: createAction(ADMIN.FETCH_VEHICLE_TYPES),
  fetchEngineCapacities: createAction(ADMIN.FETCH_ENGINE_CAPACITIES),
  fetchMotorbikeBrands: createAction(ADMIN.FETCH_MOTORBIKE_BRANDS),
  fetchMotorbikeModels: createAction(ADMIN.FETCH_MOTORBIKE_MODELS),
  fetchMotorbikeCategories: createAction(ADMIN.FETCH_MOTORBIKE_CATEGORIES),

  clearAlert: createAction(ADMIN.CLEAR_ALERT),
  openAlert: createAction(ADMIN.OPEN_ALERT),
  clearAlertDelete: createAction(ADMIN.CLEAR_ALERT_DELETE),
  openAlertDelete: createAction(ADMIN.OPEN_ALERT_DELETE),
  setPagination: createAction(ADMIN.SET_PAGINATION, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetControls: createAction(ADMIN.RESET_CONTROLS),
  changeVehicleTypeControls: createAction(ADMIN.CHANGE_VEHICLE_TYPE_RESET_CONTROLS),
  toggleFilter: createAction(ADMIN.TOGGLE_FILTER),

  toggleDisableBike: createAction(ADMIN.TOGGLE_DISABLE_BIKE, 'payload'),

  resetPagination: createAction(ADMIN.RESET_PAGINATION),

  showDisableDialog: createAction(ADMIN.SHOW_DISABLE_DIALOG, 'payload'),

  setDisableFormDialog: createAction(ADMIN.SET_DISABLE_FORM_DIALOG, 'payload'),
};

const initialState = {
  formControls: {
    email: '',
    rut: '',
    serial: '',
    categoryId: '',
    brandId: [],
    modelId: [],
    state: '',
    disable: true,
    reported: false,
    document: '',
    vehicleTypeId: 1,
    engineCapacityId: '',
    engineSerial: '',
    chassisSerial: '',
  },
  showFilter: true,
  loading: false,
  error: false,
  message: '',
  alert: false,
  alertDelete: false,
  deleteSuccess: false,
  pagination: {
    limit: 10,
    offset: 0,
    total: 0,
    page: 0,
  },
  disableDialog: {
    show: false,
    bikeId: 0,
  },
  disableFormDialog: {
    reason: '',
    disabledReasonIds: [],
  },
};

const admin = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [ADMIN.RESET_CONTROLS](state) {
    state.formControls = { ...initialState.formControls };
    // state.successRegister = false;
    // state.successEdit = false;
    // state.successUpdateReport = false;
    // state.alert = false;
    // state.alertSerial = false;
  },
  [ADMIN.CHANGE_VEHICLE_TYPE_RESET_CONTROLS](state) {
    state.formControls = {
      ...initialState.formControls,
      vehicleTypeId: state.formControls.vehicleTypeId,
    };
    // state.successRegister = false;
    // state.successEdit = false;
    // state.successUpdateReport = false;
    // state.alert = false;
    // state.alertSerial = false;
  },
  [ADMIN.FETCH_BIKES](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_BIKES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_BIKES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },

  [ADMIN.FETCH_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_BRANDS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_MODELS](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_MODELS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_MODELS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_CATEGORIES](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_CATEGORIES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_CATEGORIES_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_VEHICLE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_VEHICLE_TYPES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_VEHICLE_TYPES_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_ENGINE_CAPACITIES](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_ENGINE_CAPACITIES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_ENGINE_CAPACITIES_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_MOTORBIKE_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_BRANDS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_MOTORBIKE_MODELS](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_MODELS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_MODELS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.FETCH_MOTORBIKE_CATEGORIES](state) {
    state.loading = true;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_CATEGORIES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [ADMIN.FETCH_MOTORBIKE_CATEGORIES_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [ADMIN.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [ADMIN.OPEN_ALERT](state) {
    state.alert = true;
  },
  [ADMIN.CLEAR_ALERT_DELETE](state) {
    state.alertDelete = false;
  },
  [ADMIN.OPEN_ALERT_DELETE](state) {
    state.alertDelete = true;
  },
  [ADMIN.SET_PAGINATION](state, action) {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  [ADMIN.TOGGLE_FILTER](state) {
    state.showFilter = !state.showFilter;
  },
  [ADMIN.TOGGLE_DISABLE_BIKE](state) {
    state.loading = true;
  },
  [ADMIN.TOGGLE_DISABLE_BIKE_SUCCESS](state) {
    state.loading = false;
    state.disableDialog = {
      ...state.disableDialog,
      ...initialState.disableDialog,
    };
    state.disableFormDialog = {
      ...state.disableFormDialog,
      ...initialState.disableFormDialog,
    };
  },
  [ADMIN.TOGGLE_DISABLE_BIKE_ERROR](state) {
    state.loading = false;
  },
  [ADMIN.RESET_PAGINATION](state) {
    state.pagination = { ...initialState.pagination };
  },
  [ADMIN.SHOW_DISABLE_DIALOG](state, action) {
    state.disableDialog = {
      ...state.disableDialog,
      show: action.payload.show,
      bikeId: action.payload.bikeId,
    };
  },
  [ADMIN.SET_DISABLE_FORM_DIALOG](state, action) {
    state.disableFormDialog = {
      ...state.disableFormDialog,
      ...action.payload,
    };
  },
});

export default admin;
