/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import {
  withStyles, Grid, Typography, Button,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useParams, useLocation } from 'react-router-dom';

import { composedComponent } from 'utils/functions';

import { PATHS } from 'utils/paths';
import bankDataScreenActions from './bankDataScreen.actions';
import bankDataSaga from './bankDataScreen.saga';

import BankDataForm from './components/BankDataForm';

import styles from './styles';

const BankDataScreen = (props) => {
  const {
    classes, bankData: bankDataState, actions, history, user,
  } = props;

  // state values
  const {
    loaders,
    banks,
    accountTypes,
    bankDataId,
    errors,
    formValues: stateFormValues,
  } = bankDataState;

  const {
    getBankData,
    setUserDataBank,
    resetBankDataId,
    toggleError,
    getStoreBankData,
    setStoreBankData,
  } = actions;

  const {
    formContainer, generalContainer, titleAlertContainer, buttonsContainer,
  } = classes;

  const location = useLocation();
  console.log(
    'LOCATION->',
    location.pathname,
    location.pathname === PATHS.STORE_BANK_DATA,
    PATHS.STORE_BANK_DATA,
  );
  const isStoreBankDataPage = location?.pathname === PATHS.STORE_BANK_DATA;

  const { transferId } = useParams();
  const submitButtonRef = useRef();

  useEffect(() => {
    if (isStoreBankDataPage) {
      getStoreBankData({ userId: user.user.id });
    } else {
      getBankData({ transferId });
    }
  }, []);

  useEffect(() => {
    if (!isStoreBankDataPage) {
      if (bankDataId !== 0) {
        history.push({ pathname: '/', state: { bikeFilter: 3 } });
      }

      return () => {
        resetBankDataId();
      };
    }
  }, [bankDataId]);

  const handleClickContinueButton = () => submitButtonRef?.current.click();
  // TO DO< ADD FILTER

  const handleClickGoBackButton = () => history.push({
    pathname: '/home',
    search: '?filter=3',
    state: { bikeFilter: 3 },
  });

  const setBankData = (formValues) => {
    // TODO: ADD VALIDATIONS *
    let isErrors = false;
    if (
      formValues.userName === ''
      || formValues.rut === ''
      || formValues.email === ''
      || formValues.bankId === 0
      || formValues.accountTypeId === 0
      || formValues.accountNumber === ''
    ) {
      isErrors = true;
    }
    if (isErrors) {
      return toggleError({
        isErrors: true,
        errorMessage: 'Todos los campos son obligatorios',
      });
    }

    if (isStoreBankDataPage) {
      return setStoreBankData({ formValues, userId: user.user.id });
    }
    return setUserDataBank({ formValues, transferId });

    /* return !isErrors
      ? setUserDataBank({ formValues, transferId })
      : toggleError({
          isErrors: true,
          errorMessage: 'Todos los campos son obligatorios',
        }); */
  };

  return (
    <Grid container className={`${generalContainer}`}>
      <Grid container item xs={12} className={`${titleAlertContainer}`} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3" className="title">
            Datos Bancarios
          </Typography>

          <Alert severity="info" className="alert">
            {isStoreBankDataPage
              ? 'Ingresa tus datos para poder realizar los pagos de cupones de canje en parte de pago'
              : 'Ingresa tus datos bancarios para poder realizar el pago de tu Bike'}
          </Alert>
        </Grid>

        {loaders.isLoading ? (
          <h1>Cargando</h1>
        ) : (
          <>
            <BankDataForm
              className={`${formContainer}`}
              banks={banks}
              accountTypes={accountTypes}
              submitButtonRef={submitButtonRef}
              setBankData={setBankData}
              stateFormValues={stateFormValues}
              isStoreBankDataPage={isStoreBankDataPage}
            />
            {errors.isErrors && (
              <Alert severity="warning">
                <AlertTitle>Advertencia</AlertTitle>
                {errors.errorMessage}
              </Alert>
            )}
          </>
        )}
      </Grid>
      <Grid container className={`${buttonsContainer}`}>
        {/* TODO: add button loader */}
        {!isStoreBankDataPage && (
          <Button
            variant="contained"
            color="secondary"
            className="goBackButton"
            onClick={handleClickGoBackButton}
            disabled={loaders.isFormLoading}
          >
            Volver
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickContinueButton}
          disabled={loaders.isFormLoading}
        >
          Actualizar
          {loaders.isFormLoading && (
            <CircularProgress
              style={{
                color: 'white',
                height: '20px',
                width: '20px',
                fontSize: '2px',
                marginLeft: '4px',
              }}
            />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default composedComponent(BankDataScreen, bankDataSaga, {
  saga: 'bankDataSaga',
  middlewares: [withStyles(styles)],
  states: ['bankData', 'app.user'],
  actions: [bankDataScreenActions],
});
