import {
  ButtonBase, Grid, Paper, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StarIcon from '@material-ui/icons/Star';

const FilterBikfyPublicationsButton = (props) => {
  const {
    classes, actions, filterForm, hideIconBreakpoint,
  } = props;

  const handleClick = () => {
    actions.setFilterForm({
      filterForm: {
        ...filterForm,
        searchBikfyPublications: !filterForm.searchBikfyPublications,
      },
    });
    actions.resetMarketplacePagination();
    actions.getPublishedBikes();
  };

  return (
    <></>
    // Hidden for prod
    // <ButtonBase onClick={handleClick} className={classes.searchBikfyPublicationsButtonBase}>
    //   <Grid
    //     container
    //     item
    //     xs={12}
    //     component={Paper}
    //     alignItems="center"
    //     justifyContent="center"
    //     className={
    //       filterForm.searchBikfyPublications
    //         ? classes.marketplacePublicationsGrid
    //         : classes.bikfyPublicationsGrid
    //     }>
    //     {' '}
    //     {!hideIconBreakpoint && (
    //       <Grid
    //         item
    //         style={{
    //           color: filterForm.searchBikfyPublications ? 'white' : '#FFD700',
    //           paddingTop: '5px',
    //           marginRight: '3px',
    //         }}>
    //         {filterForm.searchBikfyPublications ? <StorefrontIcon /> : <StarIcon />}
    //       </Grid>
    //     )}
    //     <Grid item>
    //       <Typography align="center" className={classes.showBikfyPublicationsButtonText}>
    //         {filterForm.searchBikfyPublications
    //           ? 'MOSTRAR BIKES MARKETPLACE'
    //           : 'MOSTRAR SEMI-NUEVAS BIKFY'}
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </ButtonBase>
  );
};

export default FilterBikfyPublicationsButton;
