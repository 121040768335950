import React from 'react';
// MATERIAL UI
import { Tab, Tabs, Typography, Box, AppBar, Grid } from '@material-ui/core';

// MATERIAL UI ICONS
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';

import BikfyReport from 'screens/Search/components/BikfyReport/BikfyReport';
import WorkshopBook from 'screens/Search/components/WorkshopBook/WorkshopBook';
import NotLoggedUserMessage from 'commons/components/NotLoggedUserMessage/NotLoggedUserMessage';
import useCustomTabsStyles from './customTabsStyles';
import CustomTab from './CustomTab';

const CustomsTabs = (props) => {
  const { bikfyReportBikeInfo, buyer, maintenances, handleGetFile, user } = props;

  const classes = useCustomTabsStyles();
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.generalTabsContainer}>
      <AppBar position="static" className="app-bar">
        <Tabs className="tabs" value={value} onChange={handleChangeTabs}>
          <Tab className="tab" label="Contacto" />
          <Tab className="tab" label="Informe BIKFY" />
          <Tab className="tab" label="Libro Taller" />
        </Tabs>
      </AppBar>
      <Box className="custom-tab-container">
        <CustomTab value={value} index={0} className="custom-tab">
          <Grid
            container
            className="contact-info-tab-container"
            spacing={3}
            itemProp="seller"
            itemScope
            itemType="http://schema.org/Person">
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <PersonOutlineOutlinedIcon />
              </Box>
              <Box>
                <Typography>Vendedor</Typography>
                <Typography itemProp="name">{buyer?.name}</Typography>
              </Box>
            </Grid>
            {user && (
              <Grid item md={6} xs={12} className="contact-info">
                <Box className="icon">
                  <MailOutlineIcon />
                </Box>
                <Box>
                  <Typography>Correo electrónico</Typography>
                  <Typography>{buyer?.email}</Typography>
                </Box>
              </Grid>
            )}
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <ApartmentOutlinedIcon />
              </Box>
              <Box>
                <Typography>Región</Typography>
                <Typography>{buyer?.region}</Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <ApartmentOutlinedIcon />
              </Box>
              <Box>
                <Typography>Comuna</Typography>
                <Typography>{buyer?.commune}</Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomTab>
        <CustomTab value={value} index={1} className="custom-tab">
          <Box className="bikfy-report-tab-container">
            {user ? (
              <BikfyReport
                bike={{
                  ...bikfyReportBikeInfo,
                  currentOwner: buyer?.name,
                }}
                userType={user?.user?.type}
              />
            ) : (
              <NotLoggedUserMessage
                title="informe BIKFY"
                info="Podrás ver toda la información relacionada a la Bike, como su documentación, cantidad de dueños, entre otras cosas."
              />
            )}
          </Box>
        </CustomTab>
        <CustomTab value={value} index={2} className="custom-tab">
          <Box className="workshop-book-tab-container">
            {user ? (
              <WorkshopBook
                maintenances={maintenances}
                showGobackButton={false}
                handleGetFile={handleGetFile}
              />
            ) : (
              <NotLoggedUserMessage
                title="Libro Taller"
                info="Podrás ver toda la información relacionada a los servicios y mantenciones de la Bike"
              />
            )}
          </Box>
        </CustomTab>
      </Box>
    </Box>
  );
};

export default CustomsTabs;
